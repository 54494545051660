@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';

.navbar-background {
  background-color: black;
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;

  
}

.banner-container {
  // margin-top: 100px;
    // display: flex;
    width: 82%;
    max-width: $maxWidth;
    // justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: -5px;
    padding-top: 80px;
    @include respond-to(medium) {
      width: 100%;
      flex-direction: column;
      
    }

}
.banner-content {
    display: flex;
    justify-content: center;
    @include respond-to(medium) {
      flex-direction: column;
    }
}

.banner_pic {
    width: 100%;
    @include respond-to(medium) {
      width: 100%;
    }
}

.banner-text-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
}

.img-banner-1 {
  display: flex;
  justify-content: center;
  width: 70%;
  @include respond-to(medium) {
    width: 100%;
    padding: 0px !important;
  }
}
.banner-text-content h4 {
  color: #0F474A;
  font-size: 33px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  width: 408px;
    @include respond-to(medium) {
      font-size: 28px;
      font-weight: bold;
      width: 100%;
      padding: 20px 10px 0px 20px;
    }
}

.banner-text-content span {
  color: grey;
  font-size: 15px;
  font-family: SharpGrotesk;
  font-weight: bold;
  line-height: 2;
  color: #606060;
  width: 300px;
  @include respond-to(medium) {
    padding: 10px 10px 20px 20px;
    font-size: 14px;
    width: 89%;
  }
}

.second-banner-container-background {
  background: #D6E8EB;
}

.second-banner-container {
  // padding: 164px 20px;
  max-width: 1380px;
  position: relative;
  width: 80%;
  margin: auto;
  height: 339px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: grey;
  font-family: SharpGrotesk;
  line-height: 1.7;
  font-size: 16px;
  justify-content: center;
    @include respond-to(medium) {
      padding: 63px 3px;
      gap: 20px;
      height: 100%;
    }
}

.phrase-dots-partners {
  font-size: 10px;
  color: #009080;
  position: absolute;
  top: 67px;
  left: 0px;
    @include respond-to('medium') {
      width: 100%;
      font-size: 10px;
      top: 25px !important;
      left: 0px;
     }
  }

.phrase-dots-partners-2  {
  font-size: 10px;
  color: #009080;
  position: absolute;
  top: 12px;
  left: 0px;
    @include respond-to('small') {
      width: 100%;

     }
  }

  .card-img-container img{
    width: 100%;
    height: 100%;
  }
  .card-img-container {
    height: 370px;
    overflow: hidden;
  }


  .vanib {
    font-family: Georgia;
    font-weight: bold;
    color: #0F4749;
    font-size: 35px;
    margin-bottom: 46px;
  }

  .cards-details-container {
    width: 80%;
    max-width: $maxWidth;
    margin: auto;
    padding-top: 108px;
    font-size: 13px;
    color: grey;
    display: flex;
    @include respond-to(medium) {
      width: 100%;
      padding: 0px;
      display: none;
    }
  }

  .details-container-mobile {
    display: none;
    @include respond-to(medium) {
      height: 216px;
      display: flex;
      margin: auto;
      margin-top: -64px;
    }
  }

  .details-container {
    width: 80%;
    display: flex;
    max-width: $maxWidth;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
   @include respond-to(medium) {
    display: none;
   }
  }

  .service-value-container-background {
    background-color: #001109;
  }

  .service-value-container {
    position: relative;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: #001109;
    width: 80%;
    display: flex;
    margin: auto;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    padding: 63px 0px 17px 0px;
    font-family: Georgia;
    margin-top: 95px;
    @include respond-to(medium) {
      padding: 0px;
      background-image: none;
      width: 100%;
    }
  }

  .spans-desk h4 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 45px;
  }
  .spans-desk {
    display: inline;
    @include respond-to(medium) {
      display: none;
    }
  }

  .service-value-container h4 {
    color: white;
  }

  .spans-mobile-container h4{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    @include respond-to(medium) {
      padding: 30px 30px 10px 30px;
      position: relative;

    }
  }
  .spans-mobile-container p{
    color:white;
    font-size: 10px; 
    padding:10px 0px 10px 0px;
    @include respond-to(medium) {
      padding: 20px;
    }
  }
  .spans-mobile-container {
    display: none; 
    @include respond-to(medium) {
      display: inline;
    }
  }

  .spans {
    display: flex;
    margin-top: 26px;
    height: 52px;
    @include respond-to(medium) {
      display: none;
    }
  }

  .spans-desk p {
    color: white;
    font-size: 12px;
    padding: 27px 8px;
    width: 107%;
  }

  .spans-mobile .simple-fee{
    display: none;
    @include respond-to(medium) {
      background-color: #00907E;
      padding: 8px 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 20px 0px 0px 20px;
      color: white;
      right: 0;
      width: 89%;
      margin-bottom: 20px;
      top: 110px;
      position: absolute;
    }
  }
  .spans-mobile .discount{
    display: none;
    @include respond-to(medium) {
      background-color: white;
    padding: 4px 0px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 89%;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    color: #00907E;
    position: absolute;
    top: 180px;
    left: 0;
    }
  }

  .disc-text p {
    @include respond-to(medium) {
      font-size: 15px;
      margin-top: 170px;
    }
  }

  .disc-text {
    @include respond-to(medium) {
      margin: auto;
      margin-top: 140px;
      text-align: left;
      width: 91%;
    }
  }

  .spans-mobile {
    display: none;
    @include respond-to(medium) {
      display: block;
      width: 100%;
      gap: 30px;
    }
  }

  

  .details-icons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
    @include respond-to(medium) {
      justify-content: unset;
    }
  }
  .details-icons-container img {
    width: 28%;
    height: 200px;
    @include respond-to(medium) {
      width: 35%;
      height: 85%;
    }
  }

  .icon-pair {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .simple-fee span {
    font-size: 20px;
    font-family: SharpBold;
    font-weight: 600;
    @include respond-to(medium) {
      font-size: 18px;
      margin-right: 50px;
    }
  }
  .simple-fee {
    background-color: #00907E;
    padding: 10px 0px 10px 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px 0px 0px 20px;
    color: white;
  }

  .discount strong {
    @include respond-to(medium) {
      font-family: SharpSemiBold20;
    }
  }
  .discount {
    background-color: white;
    padding: 10px 0px 10px 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    color: #00907E;
  }

  .discount span{
    font-size: 12px;
    // font-family: SharpBold;
    // font-weight: 600;
    @include respond-to(medium) {
      font-size: 16px;
      margin-left: 36px;
    }
  }

  .small-letter-discount {
    font-size: 12px;
    @include respond-to(medium) {
      font-size: 10px;
    }
  }


  .start-now-btn {
    background-color: #FFFFFF;
    font-size: 21px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
    padding: 9px 15px;
    border-radius: 11px 0px 11px 11px;
    margin-left: 146px;
    white-space: nowrap;
    position: absolute;
    top: 79px;
    @include respond-to(medium) {
      padding: 5px;
      width: 60%;
      font-family: Vanib;
      margin-left: 0px;
      font-weight: bold;
      font-size: 23px;
      margin-top: 0px;
      position: absolute;
      top: 0;
    }
  }

  .start-now-btn-2 {
    background-color: #FFFFFF;
    font-size: 18px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
    padding: 12px 8px;
    border-radius: 7px 0px 7px 7px;
    white-space: nowrap;
    @include respond-to(medium) {
      padding: 10px;
      width: 82%;
      font-family: Vanib;
      font-weight: bold;
      font-size: 20px;
      margin-top: 40px;
    }
  }

  .btn-start-now-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    @include respond-to(medium) {
      margin-bottom: 20px;
      // width: 60%;
      margin: auto;
      padding-bottom: 45px;
      position: relative;
    }
  }

  .after-kitchen{
    background-image: url('../../assets/kitchen-before-100.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    background-attachment: local;
  }
  .btn-start-now-container-2 button{
    background: #00907E;
    color: white;
    // width: 30%;
    font-family: Vanib;
    font-weight: bold;
    font-size: 26px;
    padding: 10px 30px;
    margin-top: 21px;
    @include respond-to(medium) {
      padding: 10px;
      width: 82%;
      font-family: Vanib;
      font-weight: bold;
      font-size: 20px;
      margin-top: 40px;
    }
  }
  .btn-start-now-container-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 100%;
    @include respond-to(medium) {
      width: 100%;
      // display: block;
      justify-content: center;
      // padding: 25px;
    }
  }
  .know-more-btn {
    border-radius: 4px;
    // padding: 10px 5px 3px 5px;
    font-family: Vanib, arial;
    width: unset;
    padding: 10px 30px;
    @include respond-to(medium) {
      margin-bottom: 40px;
    }
  }
  .btn-start-now {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .start-now-banner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: $maxWidth;
    padding: 0;
    justify-content: center;
    font-family: SharpBold;
    font-size: 16px;
    @include respond-to(medium) {
      padding-top: 30px;
      flex-direction: column;
      height: 600px;
    }
  }

  .benefits-sub {
    font-size: 19px;
    font-weight: 300;
    font-family: SharpBold;
    color: #0F4749;
    margin-top: 15px;
    margin-bottom: -20px;
    @include respond-to(medium) {
      font-size: 12px;
      margin-top: 9px;
      font-weight: unset;
      margin-bottom: unset;
    }
  } 

  .benefits-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 81%;
    top: 0;
    max-width: $maxWidth;
    position: relative;
    color: grey;
    padding: 50px 0px 40px 0px;
    margin: auto;
    margin-top: 48px;
    @include respond-to(medium) {
      // padding: 30px;
      width: 80%;
      flex-wrap: nowrap;
    }
  }
  
  .benefits-container h4 {
    font-family: Georgia;
    font-weight: bold;
    color: #0F4749;
    font-size: 42px;
    width: 769px;
    @include respond-to(medium) {
      width: 100%;
      font-size: 30px;
    }
  }

  .benefit-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    padding: 19px 13px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #909090;
    height: 170px;
    width: 23%;
    // width: 313px;
  }

  .benefit-card p {
    color: #606060;
    // font-size: 12px;
    font-size: 63%;
    text-wrap: balance;
  }
  .benefit-card h5 {
    height: 25px;
    font-family: Georgia;
    font-size: 15px;
    font-weight: bold !important;
    color: #606060;
  }

  .benefits-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-right: -15px;
    // margin-left: -15px;
    margin-top: 75px;
    @include respond-to(medium) {
      display: none;
    }
  }

  .brochure-link {
    font-size: 17px;
    color: #009080;
    font-weight: 200;
    font-family: SharpGrotesk;
  }

  .improve-design-container .spans {
    font-family: SharpGrotesk;
    font-weight: bold;
    font-size: 17px;
    color: $greenFont;
    @include respond-to(medium) {
      font-size: 15px;
      display: block;
      margin-bottom: 100px;
      
    }
  }
  .improve-design-container {
    display: flex;
    width: 100%;
    padding: 30px 0px 30px 0px;
    margin-top: 33px;
    @include respond-to(medium) {
      flex-direction: column;
      margin-top: 65px;
    }
  }

  .grey-line {
    border-top: 1px solid rgb(223 223 223);
    width: 100%;
    height: 5px;
    margin: auto;
    margin-top: 44px;
    margin-bottom: 108px;
  }

.partners-slider-container span {
  font-size: 29px;
  margin-bottom: 30px;
  @include respond-to(medium) {
    width: 50%;
    text-align: center;
    font-size: 19px;
  }
}
.partners-slider-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: -20px;
  font-family: SharpGrotesk;
}

.something-else-container h5 {
  font-size: 25px;
  margin-top: 50px;
  color: #0F4749;
  font-family: SharpGrotesk;
  font-weight: bold;
}

.something-else-container {
  padding: 70px 0px 40px 0px;
  width: 80%;
  max-width: $maxWidth;
  margin: auto;
  height: auto;
  @include respond-to(medium) {
    padding: 9px;
    height: fit-content;
  }
}

.slider-slide h4 {
  @include respond-to(medium) {
    font-size: 20px !important;
  }
}

.details-slider-container h4{
  @include respond-to(medium) {
    font-family: SharpBold;
    // font-weight: bold;
    font-size: 30px;
    margin-top: 18px;
  }
}
.details-slider-container {
  display: none;
  @include respond-to(medium) {
    padding: 25px;
    display: flex;
    flex-direction: column;
    // gap: 30px;

  }
}

.second-banner-container p {
  font-size: 16px;
}
.details-slide-container h4 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  color: $greenFont;
  font-size: 40px;
  @include respond-to(medium) {
    font-size: 29px;
  }
}

.details-slide-container {
  display: none;
  @include respond-to(medium) {
    padding: 70px 40px 0px;
    display: flex;
    flex-direction: column;
    // gap: 30px;

  }
}

.details-container-without-slider {
  display: flex;
  gap: 9px;
  @include respond-to(medium) {
   display: none !important; 
  }
}

.icons-container span {
  font-size: 19px;
  font-family: SharpBold;
  @include respond-to(medium) {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}
.icons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 173px;
  width: 250px;
  @include respond-to(medium) {
    height: 130px;
  }
}

.flower {
  width: 39px;
  position: absolute;
  left: -17px;
  top: -31px;
  @include respond-to(medium) {
    width: 17px;
    position: absolute;
    left: 65px;
    top: 40px;
  }
}

.line-logo {
  width: 520px;
  position: absolute;
  right: -104px;
  bottom: 0;
  @include respond-to(medium) {
    width: 62%;
    position: absolute;
    bottom: -22px;
    left: -5px;
  }
}
.line-logo-2 {
  width: 400px;
  position: absolute;
  right: 17px;
  top: 9%;
  @include respond-to(medium) {
    display: none;
    // width: 62%;
    // position: absolute;
    // bottom: -22px;
    // left: -5px;
  }
}

.slider-benefits {
  position: relative;
  text-align: center;
  display: none;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @include respond-to(medium) {
    // padding: 0px 20px 20px;
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    height: 260px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  }
}

.details-cards p {
  font-size: 14px;
  line-height: 1.8;
  color: #a8a8a8;
  font-family: SharpGrotesk;
  text-wrap: balance;
}

.details-cards h4 {
  color: black;
  padding: 26px 0px 4px;
  font-family: SharpBold;
  font-size: 19px;
  margin-top: 6px;
  @include respond-to(medium) {
    font-family: SharpBold;
    font-weight: 300;
  }
}

.vertical-line-separator {
  height: 50px;
  border: 1px solid black;
}

.slider-title {
  width: 400px;
  display: flex;
  justify-content: space-around;
  margin-left: 67px;
  @include respond-to(medium) {
    margin-left: 0px;
  }
}

.quote-partners {
  // line-height: 2;
  @include respond-to(medium) {
    font-size: 13px;
  }
}