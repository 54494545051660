@import '../styles/breakpoints.scss';
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.linea-properties {
    border-bottom: 1px solid #d8d8d8;
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
    overflow: visible;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
    margin: 5px 0px 5px 0px;
}

.filter-back {
    background-color: #f6f6f6;
    padding: 15px;
    border-bottom: 2px solid #20b19d;
}

.texto-precio {
    background-image: linear-gradient(to right, #65b4a4, #20b19d);
    color: var(--color-green);
    font-family: Nova;
    font-weight: 800;
    letter-spacing: var(--letter-spacing);
    padding: 6px 40px;
    cursor: pointer;
    border-radius: 0em 5em 5em 0em;
    position: absolute;
    background: white;
    top: -40px;
    opacity: 0.8;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.input-calendar-icon {
    background-image: url('../../assets/1. INICIO/3.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 18px;
}

.from-precio-prop {
    display: flex;
    align-items: center;
    font-size: .5rem;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
    line-height: 2rem;
}

.currency-precio-prop {
    font-size: 1rem;
    font-family: NovaBlack;
}

.rate-precio-prop {
    font-size: 15px;
    font-family: NovaBlack;
}

.inputs-props {
    background-color: #fff !important;
    border-radius: 3rem;
    border: transparent;
    padding: 10px 30px;
    width: 200px;
    cursor: pointer;
    font-family: Nova;
    font-size: 1rem;
    color: #374a49;
    text-align: left;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.title-name {
    margin-bottom: 0.5rem;
    color: $greenFont;
    font-weight: bold;
    font-family: Georgia;
}

// .title-name:hover {
//     font-family: NovaBlack;
// }

.city {
    color: #374a49 !important;
    font-size: 1.2rem;
    font-family: Nova;
    font-weight: 900;
    letter-spacing: var(--letter-spacing);
    line-height: 1.6rem;
}

.type-text {
    color: #374a49 !important;
    font-size: .8rem;
    font-weight: 600;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2rem;
}

.page-text {
    color: #374a49 !important;
    font-size: 1rem;
    font-weight: 600;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
    line-height: 2rem;
}

.button-ver {
    background-image: linear-gradient(to right, #65b4a4, #20b19d);
    font-family: Nova;
    border-radius: 2rem;
    letter-spacing: var(--letter-spacing);
    color: white;
    border: transparent;
    font-size: .8rem;
    font-weight: 500 !important;
    float: right;
    padding: 3px 20px;
}

.color-price {
    color: #20b19d !important;
    font-family: NovaBlack;
    font-size: .8rem;
    letter-spacing: var(--letter-spacing);
}

.searchProducts {
    margin-bottom: 5px;
}

.searchProducts:nth-child(even) {
    background-color: #f6f6f6;
}

.truncate {
    // text-overflow: ellipsis;
    // max-width: 100%;
    // color: #20b19d;
    // text-align: justify;
    // padding: 10px
    font-size: 1rem;
}

.propertyListInfoBlock {
    padding: 15px 5px;
}

.sociales {
    background-position: center center;
    background-size: contain;
    cursor: pointer;
}

.justify-center {
    justify-content: center;
    align-content: center;
}

.propertyImageList {
    height: 265px;
}

button.btnSubmit.btnFilterSearch {
    position: initial;
    padding: 10px;
    height: 42px;
}

.color-green {
    color: #20B19D !important;
}

.loading-container {
    height: 33vw;
    text-align: center;
    margin-top: 15vw;
}

.margin-left-none {
    margin-left: 0px !important;
}

.btn-filter {
    background-image: linear-gradient(to right, #57b4a4, #20b19d);
    font-family: NovaBlack;
    border-radius: 3rem;
    color: white;
    border: transparent;
    font-size: .8rem;
    padding: 5px 15px;
}

ul.pagination li.item {
    margin-left: 3px;
    margin-right: 3px;
    border: 1px solid #20b19d;
    border-radius: 50%;
    padding: 0px 9px;
    background: white;
    cursor: pointer;
    height: 27px;
}

ul.pagination li.iconItem {
    cursor: pointer;
}

ul.pagination {
    float: right;
}

ul.pagination li.item:hover,
ul.pagination li.item.active {
    background: #20b19d;
    color: white;
}

.align-right {
    text-align: right;
}

.minHeight {
    min-height: 260px;
}

@media (max-width: 768px) {
    .minHeight {
        min-height: 210px;
    }

    .inputs-props {
        background-color: #fff !important;
        border-radius: 3rem;
        border: transparent;
        width: 220px;
        cursor: pointer;
        font-family: Nova;
        font-size: 1rem;
        color: #374a49;
        margin-top: 20px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .align-center-mobile {
        text-align: center;
    }

    button.btnSubmit.btnFilterSearch {
        position: initial;
        padding: 10px;
        height: 42px;
        margin-top: 20px;
        width: 220px;
    }
}

.container-depart-card {
    // width: 48%;
    margin: 1%;
    // max-width: 340px;
    // min-width: 200px;
    // border: 1px solid #d1d1d1;
    border:none;
    border-radius: 8px;
    height: fit-content;
    transition: 0.4s;
    // height: 342px;
    @include respond-to('medium') {
        width: 95%;
    }
}

.container-depart-card:hover {    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 5px 0px;
    cursor: pointer;
}

.depart-location {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    color: rgb(141, 141, 141);
    margin-top: 5px;
    gap: 10px;
}


.small-icon {
    font-size: "20px";
    margin-right: "5px";
}

.align-middle {
    display: "flex";
    align-items: "center";
}

.fav-icon-container {
    display: flex;
    justify-content: flex-end;
}

.fav-icon {
    position: absolute;
    z-index: 55;
    width: 31px;
    cursor: pointer;
    padding: 10px 10px 0px 0px;
    transition: 0.4s;
    z-index: 90;
    top: 2%;
    right: 3%;
}

.fav-icon:hover {
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    filter: drop-shadow(0px 3px 4px #222);
}

.open-property-card {
    height: 200px;
    background: red;
    width: 35%;
    /* margin: auto; */
    position: absolute;
    /* display: flex; */
    top: 421px;
    left: 59px;
}

.gallery-container {
    background: gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    overflow: hidden;
}

.gallery-container::before {
    background: grey;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-prop-card {
    width: 16px;
}

.icon-prop-desc {
    font-size: 10px;
}

.title-prop-list-cont {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.carousel-property-block .carousel .slide {
    height: 250px;
}