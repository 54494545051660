@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';


.featuredOff {
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  text-transform: uppercase;
  position: relative;
  transition: .5s ease;
  color: black;
}

.featuredOn {
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  color: #e39b01;
  border-bottom: 1px solid #e39b01;
  text-transform: uppercase;
  position: relative;
  transition: .5s ease;
}

.featuredOff::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #ffc506;
  transition: .5s ease;
}

.featuredOff:hover {
  color: #e39b01;
  transition-delay: .5s;
}

.featuredOff:hover::before {
  width: 100%;
}

.featuredOff::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #ffc506;
  transition: .4s ease;
  z-index: -1;
}

.featuredOff:hover::after {
  height: 100%;
  transition-delay: 0.4s;
  color: aliceblue;
}

.featured-star {
  width: 20px;
  top: 10;
  position: relative;
  top: -2px;
}

.search-home {
  @include respond-to('small') {
    padding: 20px;
  }
}



.container.search-home.no-shadow {
  box-shadow: none;
}



.inputIncremental {
  display: inline-flex;
}

.inputIncremental span {
  line-height: 1.5rem !important;
  font-size: .7rem !important;
  font-weight: 400;
  color: #374a49 !important;
  font-family: Nova;
}

.inputIncremental>div>span {
  display: inline-block;
  width: 20px !important;
  text-align: center;
}


.inputIncremental h5 {
  height: fit-content;
  font-size: 12px;
  color: #a4a4a4;
}

.favAndfeatured {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.filtersMobile {
  display: flex;
}

.home-cont {  
  
  width: $columnWidth;
  max-width: $maxWidth;
  // height: 232px;
  margin: auto;
  font-size: clamp(2vh, 2vh, 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: -10px;
    padding-top: 30px;
    @include respond-to('medium') {
      width: 90%;
     }
}

.home-cont-background {
  background-color: #00A995;
  width: 100%;
  @include respond-to(medium) {
    height: 230px;
  }
}


.phrase-dots {
  margin: 20px 0px 10px 12px; 
  color:white;
  @include respond-to('medium') {
    width: 93%;
    margin: auto;
    font-size: 10px;
   }
}
.home-cont p {
  width: 50%;
  color: white;
  font-weight: bold;
  margin-left: 13px;
  @include respond-to('medium') {
    width: 100%;
    // margin-left: 40px;
    width: 96%;
  }
}

.amenities-sect {
  @include respond-to(medium) {
    background-color: black;
  }
}

.amenities-section-backg {
  background: black;
  height: 316px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.amenities-section {
  padding-top: 11px;
  width: $columnWidth;
  margin: auto;
  max-width: $maxWidth;
  // background: black;
  // height: 316px;
  position: relative;
  color: white;
  @include respond-to('medium') {
   height: fit-content;
   background-color: black;
   width: 100%;
  }
}

#amenities-inner-text span {
  font-family: SharpGrotesk;
  font-size: 15.4px;
  font-family: Arial;
  line-height: 1;
  text-align: justify;
  width: 520px;
  line-height: 17px;
  display: block;
  @include respond-to(medium) {
    line-height: 21px;
    text-shadow: 15.2px;
    text-align: left;
    width: 106%;
  }
}

.white-logo-mobile {
  display: none;
  @include respond-to(medium) {
    display: block;
    width: 66px;
    padding: 19px 10px;
    margin: auto;
  }
}

#amenities-inner-text h2 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  white-space: nowrap;
  font-size: 40px;
  @include respond-to(medium) {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
#amenities-inner-text {
  margin-top: 46px;
  width: 50%;
  @include respond-to(medium) {
    width: 100%;
    padding: 10px 44px;
    text-align: justify;
    word-spacing: -2px;
    margin-top: -22px;
  }
}

.select-filter {
  cursor: pointer;
}

.filter-btn {
  margin: 10px;
  height: 40px;  
  background-color: transparent;
  border-bottom: 1px solid black;
  font-size: 14px;
  color: #a4a4a4;
  @include respond-to('small') {
    margin: 0px 0px 10px 0px;
    height: auto;
    width: auto;
    border-bottom: none;
  }
}

.filters-btns-container {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @include respond-to(medium) {
    // margin-left: 15px;
  }
}

.btn-filter-search {
  @include respond-to('small') {
    width: 100% !important;
    margin-top: 10px !important;
  }
}

.form-label {
  font-size: 14px;
}

.form-group-inProp .rs-picker-toggle .rs-picker-toggle-placeholder{
  font-size: 11px !important;
  font-family: SharpGrotesk !important;
}

.custom-dp-container .rs-picker-toggle-placeholder {
  font-size: 12px !important;
}

// .form-group-propGuest{
//   font-size: 13px !important;
//   background-color: #E3E3E3 !important;
// }

.form-group {
  margin-bottom: 0px !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #a4a4a4 !important;
  font-size: 1rem;
  
}

.filter-container-home {
  width: 100%;
  // padding: 10px;

  @include respond-to('small') {
    padding: 20px;
  }
}

.scroll-gif {
  display: none;

  @include respond-to('small') {
    position: fixed;
    z-index: 999;
    bottom: 17px;
    left: 44%;
    opacity: 0.4;

  }
}

#no-property-found-map {
  width: 100%;
  background-image: url('../../assets/bairesMap.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include respond-to('small') {
    width: 0%;
  }
}

.filter-input-form-alt {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white !important;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.filter-input-form {
  display: block;
  width: 100%;
  height: 39px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #a4a4a4;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.filter-input-form-prop {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a4a4a4;
  background-color: #E3E3E3;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: SharpGrotesk;
}

// .filter-input-form-prop span {
//   font-size: 2px;
// }

.rs-picker-toggle-value {
  color: #a4a4a4 !important;
  
}

.btn-featured {
  @include respond-to('small') {
    float: none !important;
    border: none !important;
    color: var(--color-black) !important;
    font-size: 0.8rem !important;
  }

  cursor: pointer;
}

.btn-featured img {
  width: 18px;
  margin-right: 5px;
}

.clear {
  clear: both;
}

.btn-featured-full {
  font-weight: bold;
}

.rs-picker-toolbar {
  visibility: hidden;
  height: 0;
  
}

.my-date-range-picker .select-new-design {
  border-bottom: 1px solid grey !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    font-size: 16px;
    background: transparent;
    color: white;
}

.rs-picker-toggle-wrapper {
  border: none;
  border-bottom: 1px solid black;
  width: 96% !important;
}

.rs-btn-subtle {
  background-color: transparent !important;
}
.my-date-range-picker {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(23, 20, 20);
  color: white;
}

.my-date-range-picker .rs-picker-toggle .rs-picker-toggle-placeholder {
  color: white !important;
}

.my-date-range-picker .rs-picker-toggle-value {
  color: white !important;
}

.my-date-range-picker .rs-picker-toggle-active .rs-picker-toggle-textbox {
  background-color: transparent !important;
  color: white !important;
}

.my-date-range-picker .rs-picker-menu{
  background-color: black !important;
  z-index: 999 !important;
}

.rs-anim-fade.rs-anim-in {
  z-index: 9999 !important;
}

.logo_container {
  @include respond-to(medium) {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logo_wallpaper_phone {
  width: 22%;
  height: 100%;
  background-image: url('../../assets/icons/whitelogo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.filter-block {
  padding: 0px 20px;
  line-height: 2;
}


.filter-container-home-dark {
  background-color:#ededed !important;
}

// .test {
//   background-color: red;
// }

.dropdown-container {
  position: relative;
}

/* Estilos para el encabezado del desplegable */
.dropdown-header {
  cursor: pointer;
  padding: 10px;
  background-color: #eee;
}

/* Estilos para las opciones del desplegable */
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.modal-filter-cont {
  position: absolute;
  z-index: 95;
  background: white;
  width: 97%;
  @include respond-to(medium) {
    width: 86%;
  }
}