.forms-modal{
    border-radius: 2rem;
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
    font-family: Nova;
    font-size: .9rem;
    color: #374a49;
    margin-top: 10px;
    border-color: lightgray;
    border-style: solid;
    background-color: #fff!important;
}
.title-modal{
    font-weight: bold!important;
    line-height: 1.8rem;
    font-size: 1.8rem!important;
    color: #374a49;
    font-family: Nova;
}
.text-modal{
    line-height: 1.8rem;
    font-size: 1.2rem!important;
    color: #374a49!important;
    font-family: Nova;
}
.button-modal{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border: transparent;
    font-size: 1rem;
    padding: 10px 50px;
}
.custom-switch{
    margin-top:15px !important;
}


.modal-content{
    padding: 0px 30px!important;
    border-radius: 40px!important;
}
.modal-body h5{
    color:var(--color-green)!important;
    font-family: NovaBlack;
    margin:0px;
}


@media (max-width: 768px){
    .modal-title{
        font-size: larger!important;
    }
    .modal-content{
        padding: 0px!important;
        border-radius: 40px!important;
    }
}


.modal-no-border  .modal-content {
    padding: 0px!important;
    border-radius: 0px !important;
}

.modal-no-border  .modal-body{
    padding:0px!important;
}

.modal-custom-border .modal-content { 
    border-radius: 10px!important;
    overflow:hidden;
}

.modal-custom-border h3{
    font-family: Georgia, "Times New Roman", Times, serifGeorgia, "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 1.5rem;
}