@import '../../../Views/styles/breakpoints.scss';
@import '../../../Views/styles/mixins.scss';

.filter-box-mobile {
    display: none;
    @include respond-to(medium) {
        display: inline;
        position: absolute;
        width: 100%;
        top: 27rem;
    }
}

.filterbox-mobile-open {
    padding: 12px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Georgia;
    font-weight: bold;
    border-radius: 9999px;
    border:2px solid black;
}

.filterbox-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 42%;
    @include respond-to(medium) {
        top: 45%;
    }
    
}

.filter-mobile {
    display: flex;
    justify-content: space-between;
    // gap: 17px;
    @include respond-to(medium) {
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding: 20px 15px 15px;
    }
}

.filterbox-btn-close {
    position: absolute;
    top: -30px;
    left: 47%;
    z-index: 600;
    background-color: white;
    padding: 7px 14px 7px 14px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform 0.5s ease-in-out;
}

.search-btn-filterbox {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include respond-to(medium) {
        display: block;
    }
}

.w2ba-main-section {
    overflow-x: hidden;
    @include respond-to(medium) {
        position: relative;
    }
}