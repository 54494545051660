.text-terms{
    color:#374a49!important;
    font-size: 1rem;
    font-family: Nova;
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
    line-height: 2rem;
}
.text-terms strong{
    color:#20b19d!important;
    font-size: 1.2rem!important;
    font-family: NovaBlack!important;
    letter-spacing: var(--letter-spacing);
    line-height: 1.5rem;
}
.linea-card-terms{
    border-bottom: 2px solid lightgray;
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
    overflow: visible;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
}
.margins-terms{
    padding: 70px 100px;
}

@media (max-width: 768px){
    .margins-terms{
        padding: 10px 10px;
    }
}