.background-publish-title{
    background-image:  url('../../assets/4. PUBLICAR/20.jpg');
    padding-top: 7%;
    padding-bottom: 7%;
    background-repeat: round;
}
.publish-back{
    background-image:  url('../../assets/7. BACKS/Recurso\ 10-8.png');
    background-position: center;
    background-size: cover;
}
.title-publish{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.8rem!important;
    color: white;
    text-align: left;
}
.subtitle-publish{
    font-size: 2.4rem;
    font-family: Nova;
    font-weight: 400;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: white;
    text-align: left;
}
.titulo-gestion{
    line-height: 2.4rem!important;
    letter-spacing: var(--letter-spacing);
    font-size: 2.4rem!important;
    color: #374a49!important;
    font-family: NovaBlack;
}
.texto-publish{
    line-height: 1.2rem!important;
    font-size: 1rem;
    font-weight: 500;
    color: #374a49!important;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
    margin-bottom: 0px;
}
.img-publish{
    background-position: center;
    background-size: contain;
}
.btnW2ba-publish{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border: transparent;
    font-size: .8rem;
    font-weight: 500!important;
    padding: 10px 70px 10px 70px;
    margin-bottom: 50px;
}
.title-publish-2{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    color: #20b19d;
    text-align: center;
    line-height: 2rem!important;
}
.subtitle-publish-2{
    font-size: 2.4rem;
    font-weight: 500;
    color:#374a49!important;
    font-family: Nova;
    text-align: center;
    line-height: 2rem!important;
    letter-spacing: var(--letter-spacing);
}
.title-servicio-publish{
    color:#20b19d!important;
    letter-spacing: var(--letter-spacing);
    font-size: 1rem;
    line-height: 1.2em!important;
    text-align: left;
    font-family: NovaBlack;
}
.texto-servicio-publish{
    color:#374a49!important;
    font-size: .8rem;
    font-weight: 500;
    line-height: 1.2em!important;
    text-align: left;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
}
.linea-publish{
    border-bottom: 1px solid  #20b19d;
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
    overflow: visible;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
}
.title-elementos-publish{
    color:#20b19d!important;
    font-size: .8rem;
    line-height: 1.2em!important;
    text-align: left;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
}
.list-publish{
    color:#374a49!important;
    font-size: .7rem;
    font-weight: 400;
    line-height: 1.2em!important;
    text-align: left;
    font-family: Nova;
}
.background-publish-button{
    background-image:  url('../../assets/4. PUBLICAR/262.jpg');
    padding-bottom: 5%;
    padding-top: 5%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.button-publish{
    background-image: linear-gradient(to right, #65b4a4 , #20b19d);
    font-family: Nova;
    font-weight: bold;
    border-radius: 2em;
    color: white;
    border: transparent;
    font-size: 1rem;
    padding: 20px 70px;
    letter-spacing: var(--letter-spacing);
}
.button-publish:hover{
    box-shadow: 0 0 15px rgba(32,177,157); 
  }
.padding-left-publish{
    padding-left: 70px;
}
.margin-left-publish{
    margin-left: 70px;
}

.box-tarifa {
    /*display: inline-grid;*/
    margin-top: 50px;
}
.box-tarifa > div {
    padding: 10px;
    text-align: center;
    border: 1px solid #20b19d;
}
.back-tarifa{
    background-color: #20b19d;
}
.text-tarifa-white{
    color: white;
    font-family: NovaBlack;
    font-size: 1.2rem;
    letter-spacing: .05rem!important;
}
.text-tarifa-green{
    color: #20b19d;
    font-family: NovaBlack;
    font-size: 1.2rem;
    letter-spacing: .05rem!important;
}
.align-left-browser{
    text-align: left;
  }

@media (max-width: 768px){
    .box-tarifa {
        display: inline-grid;
        margin-top: 20px;
    }
    .box-tarifa > div {
        padding: 10px;
        text-align: center;
        border: 1px solid #20b19d;
    }
    .background-publish-title{
        background-image:  url('../../assets/4. PUBLICAR/20.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 15%;
        padding-bottom: 15%;
    }
    .title-publish{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        color: white;
        text-align: left;
    }
    .subtitle-publish{
        font-size: 2.2rem;
        font-family: Nova;
        font-weight: 500;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        color: white;
        text-align: left;
    }
    .titulo-gestion{
        line-height: 2rem!important;
        letter-spacing: var(--letter-spacing);
        font-size: 2.2rem!important;
        color: #374a49!important;
        font-family: NovaBlack;
    }
    .texto-publish{
        line-height: 1.2rem!important;
        font-size: 1rem;
        font-weight: 500;
        color: #374a49!important;
        font-family: Nova;
        letter-spacing: var(--letter-spacing);
    }
    .title-publish-2{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        color: #20b19d;
        text-align: center;
        line-height: 2rem!important;
    }
    .subtitle-publish-2{
        font-size: 2.2rem;
        font-weight: 500;
        color:#374a49!important;
        font-family: Nova;
        text-align: center;
        line-height: 2rem!important;
        letter-spacing: var(--letter-spacing);
    }
    .align-center-mobile{
        text-align: center;
    }
    .padding-left-publish{
        padding-left: 0px;
    }
    .margin-left-publish{
        margin-left: 0px;
    }
}
