@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';

.pagination-dock {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pagination_container{
    flex:1;
    padding-top: 15px;
    padding-bottom: 15px;
}

.property-list-container {
    @include respond-to('small') {
        width: 100px;
    }
}

.min-h-1000 {
    min-height: 1000px;
}

@media only screen and (min-width: 992px) {
    .scroll-fix {
        overflow-y: scroll;
    }
}

.float-r {
    float: right;
}

.list-prop-cont {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: flex-start;
    @include respond-to(medium) {
        justify-content: center;
        padding: 0px 15px;
        margin-top: 40px;
    }
}

.prop-quantity-text {
    font-family: Nova;
    font-weight: bold;
    font-size: 20px;
    color: #57b4a4;
  }

.total-props-cont {
    display: none;
    @include respond-to(medium) {
        display: block;
        margin-left: 10px !important;
    }
}

.total-props-cont2 {
    margin-left: 10px !important;
    padding-top: 7px;
    @include respond-to(medium) {
        display: none;
    }
}

.loading-map {
    @include respond-to(medium) {
        height: 500px;
        // top: 200px;
        // width: 100%;
        // left: 50%;
        position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }
}

.prop-cont {
    width: 50%;
    display: flex;
    justify-content: center;
    @include respond-to(medium) {
        width: 100%;
    }
}
.prop-cont:nth-child(odd) {
    @include respond-to(not-mobile) {
        padding-left: 5px; /* Adjust the value as needed */
    }
}
.prop-cont:nth-child(even) {
    @include respond-to(not-mobile) {
        padding-right: 5px; /* Adjust the value as needed */
    }

}

.css-1nmdiq5-menu {
    z-index: 99 !important;
}

.props-absolute-container {
    position: absolute !important;
    top: 200px !important;
    width: 99% !important;
    @include respond-to(medium) {
        position:initial !important;
        top: unset;
        width: unset;
    }
}

//Propertieslist
.container_properties_and_map {
    display: flex;
    width: 100%;
    flex:15;
    @include respond-to(medium) {
      flex-direction: column-reverse;
    }
  }

.container_properties_list{
    overflow: hidden;
}