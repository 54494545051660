@import '../../../Views/styles/breakpoints.scss';
@import '../../../Views/styles/mixins.scss';
@import '../../../Views/styles/variables.scss';

.toggle-container-span {
    font-size: 15px;
    font-family: SharpMedium20;
    font-weight: 100;
    @include respond-to(medium) {
        font-size: 13px;
    }
}

.toggle-container {
    padding: 22px;
    width: 98%;
    border: 2px solid rgba(203, 203, 203, 0.608);
    margin: 10px;
    color: #0F4749;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 36px;
    @include respond-to(medium) {
        padding: 11px;
        width: 94%;
        margin-bottom: 11px;
    }
}

.toggle-structure {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
}

.arrow {
    font-size: 20px;
    color: #00907E;
    font-weight: bold;
}


.accordion-button{
    background-color: transparent;
}
.accordion{
    padding: 10px!important;
}