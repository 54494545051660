.title-filter {
    color: #20b19d !important;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2em !important;
    font-family: Nova;
}

.forms-filter {
    border-radius: 2rem;
    width: 100%;
    padding: 10px 50px 10px 50px;
    cursor: pointer;
    font-family: Nova;
    font-size: .9rem;
    color: #374a49;
}

.text-filter {
    line-height: 1.5rem !important;
    font-size: .7rem !important;
    font-weight: 400;
    color: #374a49 !important;
    font-family: Nova;
    width: 134px;
}

.props-absolute-container {
    position: absolute;
    top: 200px;
    width: 99%;
}