.background-design-title{
    background-image:  url('../../assets/3. INTERIORES/18.jpg');
    padding-top: 7%;
    padding-bottom: 7%;
    background-size: cover;
    background-position: center;
}
.background-design{
    background-image:  url('../../assets/7. BACKS/Recurso 9-8.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.background-proyects{
    background-image: url('../../assets/7. BACKS/Recurso\ 10-8.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.title-design{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: white;
    text-align: left;
}
.video-section-design{
    background-color: lightgray;
    height: 75vh;
    background-size: cover;
    background-position: center;
}
.potencial-back{
    background-color: #374a49;
    width: 90%;
    border-radius: 5rem;
    padding-right: 5rem;
    padding-bottom: 4rem;
    padding-top: 3rem;
    padding-left: 2rem;
}
.center-back{
    display: flex;
    align-self: center;
}
.potencial-back p{
    margin-bottom: 0px;
}
.title-design-2{
    font-size: 2.4rem;
    color: #fff;
    font-family: NovaBlack;
    line-height: 1.2em!important;
    letter-spacing: var(--letter-spacing);
}
.texto-design-2{
    line-height: 1.2rem!important;
    font-size: .9rem;
    color:#fff!important;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
}
.title-proceso{
    color: #374a49!important;
    font-size: 2.4rem!important;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    margin-left: 20%;
    margin-top: -1.2%;
}
.linea-proceso{
    border-top: 13px solid #20b19d;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    display: inline-block;
    clear: both;
    float: none;
    width: 18%;
    overflow: visible;
    vertical-align: middle;
    line-height: normal;
  }
.texto-proceso{
    color: #374a49;
    line-height: 1rem!important;
    letter-spacing: var(--letter-spacing);
    font-size: 1rem;
    font-weight: 500;
    color:#374a49!important;
    font-family: Nova;
}
.texto-proceso strong{
    color: #20b19d;
}
.button-proceso{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border-color: transparent;
    font-size: .9rem;
    font-weight: 500;
    padding: 5px 25px 5px 25px;
}
.title-proceso-2{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: #20b19d;
    text-align: center;
}
.subtitle-proceso-2{
    line-height: 1.2em!important;
    letter-spacing: var(--letter-spacing);
    font-size: 1rem;
    color:#374a49!important;
    font-family: Nova;
    text-align: center;
    font-weight: 500;
}
.name-proceso{
    font-size: 2.4rem;
    color: #374a49;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    text-align: center;
}
.title-proceso-3{
    font-size: 1.8rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: #20b19d;
    text-align: center;
}
.sociales-design{
    background-position: center;
    background-size: contain;
    cursor: pointer;
}
.align-center{
    text-align: center;
}
.margin-left-objetives{
    margin-left: 70px;
}
.button-agendar{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d)!important;
    font-family: NovaBlack;
    width: 220px;
    letter-spacing: var(--letter-spacing);
    border-radius: 2em;
    color: white;
    border-color: transparent;
    font-size: 1rem;
    padding: 10px 30px;
    position: absolute;
    left: 38%;
    top: 5px
}
.button-agendar:hover{
    box-shadow: 0 0 15px rgba(32,177,157); 
  }
.button-agendar-back{
    background-image: url('../../assets/1. INICIO/3.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 15px;
}


@media (max-width: 768px ) {
    .background-design-title{
        background-image:  url('../../assets/3. INTERIORES/18.jpg');
        padding-top: 15%;
        padding-bottom: 15%;
        background-size: cover;
        background-position: center;
    }
    .title-design{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 1.2em!important;
        color: white;
        text-align: left;
    }
    .video-section-design-mobile{
        height: auto;
        background-size: cover;
        background-position: center;
    }
    .title-proceso{
        color: #374a49!important;
        font-size: 2.2rem!important;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        margin-left: 10%;
    }
    .texto-proceso{
        color: #374a49;
        line-height: 1.2rem!important;
        letter-spacing: var(--letter-spacing);
        font-size: 1rem;
        font-weight: 500;
        color:#374a49!important;
        font-family: Nova;
        text-align: left;
    }
    .texto-design-2{
        line-height: 1.2rem!important;
        font-size: 1rem;
        color:#374a49!important;
        font-family: Nova;
        text-align: left;
        letter-spacing: var(--letter-spacing);
    }
    .margin-mobile-left{
        margin-left: 10px!important;
    }
    .title-design-2{
        font-size: 2.2rem;
        color: #374a49;
        font-family: NovaBlack;
        line-height: 2rem!important;
        letter-spacing: var(--letter-spacing);
    }
    .title-design-2Mobile{
        font-size: 2.2rem;
        color: #fff;
        font-family: NovaBlack;
        letter-spacing: .0125em!important;
        line-height: 1.2em!important;
        text-align: left;
    }
    .texto-design-2Mobile{
        line-height: 1.2rem!important;
        font-size: 1rem;
        color:#fff!important;
        font-family: Nova;
        text-align: left;
        letter-spacing: var(--letter-spacing);
    }
    .button-agendar-mobile{
        background-image: linear-gradient(to right, #57b4a4 , #20b19d);
        font-family: NovaBlack;
        letter-spacing: .05em!important;
        border-radius: 2em;
        color: white;
        border-color: transparent;
        font-size: 1rem;
        padding: 10px 40px;
    }
    .title-proceso-2{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        color: #20b19d;
        text-align: center;
    }
    .subtitle-proceso-2{
        line-height: 1.4em!important;
        letter-spacing: var(--letter-spacing);
        font-size: 1rem;
        color:#374a49!important;
        font-family: Nova;
        text-align: center;
    }
    .name-proceso{
        font-size: 2.2rem;
        color: #374a49;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 1.2em!important;
        text-align: center;
    }
    .title-proceso-3{
        font-size: 2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 1.2em!important;
        color: #20b19d;
        text-align: center;
    }
}