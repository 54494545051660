.orbirental-calendar-widget table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0 !important;
}

.orbirental-calendar-widget table {
	border-collapse: separate;
	border-spacing: 0 ;
}
.orbirental-calendar-widget caption, .orbirental-calendar-widget th, .orbirental-calendar-widget td {
	text-align: left;
	font-weight: normal;
    border: 0px solid !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td span {
	cursor: default !important;
}

.orbirental-calendar-widget div.calendar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
}

.orbirental-calendar-widget div.calendar,
.orbirental-calendar-widget div.slogan {
  margin-top: 20px !important;
  background-color: white !important;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px !important;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

.orbirental-calendar-widget .orbirental-calendar-widget-nav a {
    text-decoration: none;
    font-family: Arial;
    font-weight: bold;
    font-size: 15px;
    color:var(--color-title)!important;
}

.orbirental-calendar-widget div.slogan a:hover {
  opacity: 1;
}

.orbirental-calendar-widget div.slogan span.orbi {
  text-decoration: none;
  color: #ff6633;
}

.orbirental-calendar-widget div.slogan a {
  text-decoration: none;
  opacity: 0.3;
  color: gray;
}

.orbirental-calendar-widget div.slogan {
  text-align: right;
}

div.orbirental-calendar-widget {
	margin: 0 auto;
	/* border: 1px solid gray; */
	/* padding: 5px; */
	display: block;
	max-width: 100%;
	background-color: white;
  border-radius: 12px;
  border: none;
  /* border: 2px solid var(--color-green); */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orbirental-calendar-widget .orbirental-calendar-widget-nav {
  background-color: white !important;
  /* margin: 0 auto; */
  font-size: 12px !important;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

.orbirental-calendar-widget .orbirental-calendar-widget-nav td {
  height: 19px;
  vertical-align: middle;
  border: 0px solid;
  width: auto;
  text-align: center;
}

.orbirental-calendar-widget .orbirental-calendar-widget-nav img {
  position: absolute;
  bottom: 50%;
  right: 10px;
  padding: 0px 5px;
  vertical-align: middle;
  cursor: pointer;
}

.orbirental-calendar-widget label.propertyName {
  text-align: center;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month {
    border-collapse: separate;
    border-spacing: 0 2px;
    display: inline-table;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: 154px;
    margin: 15px;
    user-select: none;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month>tbody>tr>td:not(:first-child) {
    vertical-align: middle !important;
    width: 27px;
    height: 27px;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td div:before {
    content: none;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td div.orbirental-calendar-widget-day,
table.orbirental-calendar-widget-month td div.orbirental-calendar-widget-day table {
    height: 22px;
    width: 100%;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td div.orbirental-calendar-widget-day{
    text-align: center;
    position: relative;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month tr td.orbirental-calendar-widget-day div {
  text-align: center;
  position: relative;
  width: 100%;
  height: 22px;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td.orbirental-calendar-widget-day div span {
  height: 22px;
  font-size: 12px;
  position: absolute;
  line-height: 22px;
  width: inherit;
  top: 0;
  display: block;
  cursor: pointer;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.orbirental-calendar-widget-day-wrap div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.orbirental-calendar-widget-day-wrap div.orbirental-calendar-widget-day-afternoon {
  width: 50%;
  float: left;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_tentative div.orbirental-calendar-widget-day-afternoon,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_tentative div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.tentative div  {
  color: #ffffff;
  background-color: rgba(255, 153, 0, 1);
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_reserved div.orbirental-calendar-widget-day-afternoon,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_reserved div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.reserved div  {
  color: #ffffff;
  background-color: rgba(255, 102, 51, 1);
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_blocked div.orbirental-calendar-widget-day-afternoon,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_blocked div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.blocked div  {
  color: #ffffff;
  background-color: rgba(153, 153, 153, 1);
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_tentative div.orbirental-calendar-widget-day-afternoon,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_reserved div.orbirental-calendar-widget-day-afternoon,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_blocked div.orbirental-calendar-widget-day-afternoon {
  border-radius: 22px 0 0 22px !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_tentative div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_reserved div.orbirental-calendar-widget-day-morning,
.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_blocked div.orbirental-calendar-widget-day-morning {
  border-radius: 0 22px 22px 0 !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month tr {
    color: #000000;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month td.today span {
    background-color: rgba(102, 153, 204, 0.8);
    border-radius: 22px;
}

.orbirental-calendar-widget table tr.month_head td {
    text-align: center;
    font-size: 17px;
    font-family: SharpBold;
}

.orbirental-calendar-widget table tr.month_head {
    color: var(--color-green);
    cursor: default;
    font-family: NovaBlack;
    font-size: 12px;
}

.orbirental-calendar-widget table tr.day_head {
    font-size: 10px;
    color: #999999;
    cursor: default;
}

.orbirental-calendar-widget table tr.day_head > td{
    text-align: center;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.reserved div {
  background-color: #ED9700 !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_out_reserved div.orbirental-calendar-widget-day-morning {
  background-color: #ED9700 !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month div.check_in_reserved div.orbirental-calendar-widget-day-afternoon {
  background-color: #ED9700 !important;
}

.orbirental-calendar-widget table.orbirental-calendar-widget-month {
  width: 200px !important;
}

.orbirental-calendar-widget {
  position: relative;
}