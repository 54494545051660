@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';


.contenedor {
    overflow: hidden;
    max-height: 0;
    transition: max-height 1.2s ease-out;
    // padding-left: 20px;
  }

  .contenedor:not(.activo) {
    transition: max-height 0.3s ease-in; /* Puedes ajustar el valor aquí también */
  }

  .contenedor.activo {
    // padding-left: 20px;
    max-height: 600px; /* o el valor que desees para la altura máxima */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 7px 5px -4px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }


  .custom-filter-checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .custom-filter-checkbox-container span {
    font-size: 10px;
  }


  .custom-filter-checkbox {
    width: 13px;
    height: 13px;
    border: 1px solid grey;
    border-radius: 2px;
  }

  .cont-d-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include respond-to(medium) {
      gap: 20px;
    }
  }

  .cont-d-block {
    display: flex;
    margin-top:15px; 
    padding-bottom:10px;
    @include respond-to(medium) {
      
    }
  }

  .filters-cont-two {
    width:200px;
    padding-left:24px;
    @include respond-to(medium) {
      padding-left: 0px;
      width: auto;
    }
  }