.carousel-check {
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .carousel-slide-check {
    display: flex;
    
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
    /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
  }
  .carousel-slide-check::-webkit-scrollbar {
    width: 20px;
    height: 10px;
  }
  .carousel-slide-check::-webkit-scrollbar-thumb {
    background: #374a49;
    border-radius: 10px;
  }
  .carousel-slide-check::-webkit-scrollbar-track {
    background: transparent;
  }
  .carousel-slide-check > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 400px;
    height: 350px;
    margin-right: 10px;
    border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    
  }
  .carousel-slide-check > div:target {
     transform: scale(0.8); 
  }
  