@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';

.checkout-title-prop {
    color: #1B6765;
/*color: #20b19d;*/
font-size: 2rem;
font-family: Georgia;
font-weight: bold;
letter-spacing: var(--letter-spacing);
line-height: 3rem !important;
@include respond-to(medium) {
    line-height: unset;
    font-size: 2rem;
    color: $greenFont;
}
}



.title-prop {
    color: #1B6765;
    /*color: #20b19d;*/
    font-size: 2rem;
    font-family: Georgia;
    font-weight: bold;
    letter-spacing: var(--letter-spacing);
    line-height: 3rem !important;
}

.city-check {
    color: #A9A9A9 !important;
    font-size: 1.2rem;
    /* font-weight: 900; */
    font-family: SharpGrotesk;
    letter-spacing: var(--letter-spacing);
    @include respond-to(medium) {
        color: #606060 !important;
        font-weight: 700;
    }
}

.checkout-prop-summary {
    display: flex;
    margin: 20px 0px;
    gap: 12px;
    @include respond-to(medium) {
        flex-wrap: wrap;
    }
}

.type-check {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 12px;
    font-family: SharpGrotesk;
    @include respond-to(medium) {
        
    }
}

.type-check img {
    width: 25px;
}

.button-price-check {
    background: white;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    border-radius: 2rem;
    color: var(--color-green);
    border: 1px solid var(--color-green) !important;
    font-size: 1rem;
    padding: 5px 50px
}

.title-description {
    color: black;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: Georgia;
    letter-spacing: var(--letter-spacing);
    line-height: 1.3em !important;
    @include respond-to(medium) {
        color: $greenFont;
    }
}

.checkout-dots {
    font-size: 10px;
}

.text-description {
    font-size: 14px !important;
    /* line-height: 1.3rem; */
    font-weight: 500;
    text-align: left !important;
    color: #606060 !important;
    /* letter-spacing: var(--letter-spacing); */
    font-family: SharpGrotesk;
}

.from-precio-check {
    display: flex;
    align-items: center;
    font-size: .5rem;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
    line-height: 2rem;
}

.currency-precio-check {
    font-size: 1.4rem;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
    line-height: 2rem;
}

.rate-precio-check {
    font-size: 1.4rem;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
    line-height: 2rem;
}

.text-totalPay {
    font-size: 1rem !important;
    line-height: 1.4rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
}

.linea-check {
    border-bottom: 2px solid #20b19d;
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
    overflow: visible;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
    margin-top: 4%;
    margin-bottom: 4%;
}

.card-check {
    background-color: #fff;
    padding: 20px;
    /*border: 1px solid var(--color-title);*/
    border-radius: 2rem;
    position: relative;
    text-align: center;
}

.leadWidget {
    /* border: 4px solid #f8f8f8; */
    transition: .1s ease-in-out;
    transition-property: color, background-color, background-size, border-color, box-shadow;
    @include respond-to(medium) {
        margin: 20px;
        background-color: #E3E3E3 !important;
    }
}

.forms-check,
.leadWidget>form>div>input,
.leadWidget>form>div>select {
    background-color: #fff !important;
    border-radius: 2rem;
    width: 100%;
    padding: 10px 8px 10px 10px;
    cursor: pointer;
    font-family: Nova;
    font-size: .9rem;
    color: #374a49;
    margin-top: 4%;
    border-color: lightgray;
    border-style: solid;
    // height: 45px !important;
}

.leadWidget>form>div>input,.leadWidget>form>div>select {
    @include respond-to(medium) {
        padding: 0px 20px !important;
    }
}

.leadWidget>form {
    border: none !important;
}

.leadWidget>form>button {
    border-radius: 6px;
    background-color: #019983!important;
}

.button-reserva {
    background-image: linear-gradient(to right, #57b4a4, #20b19d);
    font-family: Georgia;
    border-radius: 0px 10px 0px 10px;
    font-weight: bold;
    color: white;
    border: transparent;
    font-size: .8rem;
    // font-weight: 500 !important;
    padding: 7px 20px;
    width: 100%;
    letter-spacing: var(--letter-spacing);
    @include respond-to(medium) {
        padding: 12px 20px;
        font-size: 1rem;
    }
}

.button-reserva-back {
    background-color: transparent;
    border: solid 1px;
    border-color: #20b19d;
    font-family: Nova;
    border-radius: 3rem;
    color: #20b19d;
    font-size: .9rem;
    font-weight: 500 !important;
    padding: 5px 20px 5px 20px;
    width: 100%;
    letter-spacing: var(--letter-spacing);
}

.button-reserva-back:hover {
    box-shadow: 0 0 15px rgba(32, 177, 157);
}

.button-reserva:hover {
    box-shadow: 0 0 15px rgba(32, 177, 157);
}

.text-reserva {
    font-size: .8rem !important;
    letter-spacing: var(--letter-spacing);
    line-height: 1rem;
    text-align: center;
    color: #374a49 !important;
    font-family: Nova;
}

.text-reserva p {
    font-size: 12px;
    padding: 10px 0px;
    @include respond-to(medium) {
        padding: 10px 40px !important;
        font-family: SharpGrotesk;
    }
}

.logo-tarjetas {
    background-position: center center;
    background-size: contain;
}

.location-map {
    width: 100%;
    height: 40vh;
    overflow: hidden;
}

.searchAmenitie:nth-child(even) {
    background-color: #f6f6f6;
}

.card-table-gray:nth-child(even) {
    background-color: #f6f6f6;
}

.custom-image-gallery {
    height: 100%;
}

.custom-image-gallery img.image-gallery-image {
    height: 100% !important;
    border-radius: 2rem !important;
}

@media (max-width: 768px) {
    // .type-check {
    //     color: #374a49 !important;
    //     font-size: .8rem;
    //     font-weight: 500;
    //     font-family: Nova;
    //     letter-spacing: var(--letter-spacing);
    // }

    .title-check {
        background-image: url('../../assets/7. BACKS/Recurso 13-8.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

   

    .title-prop {
        color: #374a49;
        font-size: 1.5rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem !important;
    }

    // .city-check {
    //     color: #374a49 !important;
    //     font-size: 1.4rem;
    //     font-weight: 900;
    //     font-family: Nova;
    //     letter-spacing: var(--letter-spacing);
    // }

    .checkoutBigPic {
        height: auto;
        width: 100%;
    }

    .checkoutSmallPic {
        height: auto;
        width: 100%;
        margin-top: 7%;
        margin-left: 2%;
        padding: 0 1% 0 1%;
        @include respond-to(medium) {
            margin-top: unset;
            margin-left: unset;
            padding: 0px;
        }
    }

    .title-check {
        background-image: url('../../assets/7. BACKS/Recurso 13-8.png');
        height: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }
}

.border-card {
    border: solid 2px lightgray;
    border-radius: 2rem;
    padding: 15px;
    width: 100%;
}

.linea-card-check {
    border-bottom: 2px solid lightgray;
    display: inline-block;
    clear: both;
    float: none;
    width: 100%;
    overflow: visible;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
}

.title-card {
    font-size: 1.4rem !important;
    line-height: 2rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
}

.subTitle-card {
    font-size: 1rem !important;
    line-height: 1.4rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
}

.name-property-card {
    font-size: 1.1rem !important;
    line-height: 2rem;
    font-weight: 500;
    text-align: left !important;
    color: #20b19d;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
}

.city-card-check {
    color: #374a49 !important;
    font-size: 1rem;
    font-weight: 500;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
}

.b-radius-card-check {
    border-radius: 2rem;
}

.text-property-card {
    font-size: .9rem !important;
    line-height: 1.4rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
}

.from-to-for-card {
    font-size: .9rem !important;
    line-height: 2rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
}

.text-property-table {
    font-size: 1rem !important;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: left !important;
    color: #374a49 !important;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
}

.color-price-card {
    color: #20b19d !important;
    font-family: NovaBlack;
}

.light-gray-color-card {
    font-size: .9rem !important;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: left !important;
    color: gray !important;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
}

.align-right-browser-button {
    text-align: right;
}

.align-left-browser-button {
    text-align: left;
}

.title-checkout-steps {
    color: #374a49 !important;
    font-family: 'NovaBlack';
    font-size: 1.4rem !important;
}

.sub-title-checkout-steps {
    color: #374a49 !important;
    font-family: 'NovaBlack';
    font-size: 1.1rem !important;
}

.forms-modal-check {
    border-radius: 2rem;
    width: 187px;
    padding: 10px 10px;
    cursor: pointer;
    font-family: Nova;
    font-size: .9rem;
    color: #374a49;
    margin-top: 10px;
    border-color: lightgray;
    border-style: solid;
}

.sticky-container {
    position: sticky !important;
    top: 30px;
    z-index: 80;
}

.checkout-title-reservation-container {
    display: flex;
    padding: 0px 7%;
    @include respond-to(medium) {
        padding: 0;
        display: block;
        flex-wrap: wrap;
    }
}

td.is-disabled {
    background-color: lightgray !important;
}

tr.pika-row>td:not(.is-disabled),
tr.pika-row>td:not(.is-disabled)>.pika-button {
    background-color: white !important;
    color: black;
}

tr.pika-row>td:not(.is-disabled)>.pika-button:hover {
    border: 1px solid grey;
}


.card_rooms_and_beeds {
    background-color: #fff;
    color: #3d3d3d;
    border: 4px solid #f8f8f8;
    padding: 20px
}

.checkout-equipment-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 21%;
    /* margin: 20px 40px; */
}

.checkout-equipment-container img {
    width: 65px;
}

.alice-carousel__stage-item {
 @include respond-to(medium) {
    // width: 423px !important;
 }   
}

.alice-carousel__wrapper {
    @include respond-to(medium) {
        max-height: 500px!important;
        padding-right: 12px !important;
    }
}

.orbirental-capture-widget-form {
    background-color: #E3E3E3 !important;
    @include respond-to(medium) {
        width: 100% !important;
        padding: 0 !important;
    }
}

.orbirental-capture-widget-wrapper .orbirental-capture-widget-price-option {
    background-color: black !important;
}

.leadWidget > form.orbirental-capture-widget-form {
    padding: 0 !important;
    border-radius: 20px 0px 0px 0px;
    @include respond-to(medium) {
        
    }
}

.header-widget {
    position: absolute;
    z-index: -1;
    width: 100%;
    height:97px;
    background: black;
    color: white;
}

.body-widget {
    position: absolute;
    background: #E3E3E3 !important;
    height: 246px;
    width: 100%;
    top: 94px;
    z-index: -1;
}

.orbirental-capture-widget-wrapper select input {
    border-radius: 10px 0px 10px 10px !important;
    font-size: 12px !important;
    color: #606060 !important;
    height: 35px !important;
}

.orbirental-capture-widget-form {
    // position: relative !important;
}

.checkout-book-now {
    position: relative !important;
}


.text-description p {
    padding: 0px 10px 0px 0px;
}

.checkout-modal-amenities-container-5 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 12px;
}

.checkout-modal-amenities-container-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 12px;
}

.checkout-modal-amenities-container-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 12px;
}

.checkout-modal span {
    color: #606060;
    font-size: 12px;
    font-family: SharpGrotesk;
}

.checkout-modal-title {
    font-family: SharpGrotesk;
    font-weight:bold;
    font-size: 17px;
    @include respond-to(medium) {
        font-size: 23px;
        margin-right: 20px;
    }
}

.checkout-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-modal-header button {
    background-color: transparent;
}
.checkout-modal-x-button img {
    width: 16px;
}

.checkout-bed-pic {
    // width: 33%;
    border-radius: 20px;
    pointer-events: none;
}

.checkout-bed-carousel {
    // width: 50%;
    // background-color: red;
    gap: 10px;
}

.alice-carousel__slide-info {
    top: -45px !important;
    right: 58px !important;
    color: unset !important;
    background: unset !important; 
    font-family: SharpBold;
}

.alice-carousel__prev-btn {
    position: absolute !important;
    top: -53px !important;
    right: 105px !important;
}

.alice-carousel__next-btn {
    position: absolute !important;
    top: -53px !important;
    right: -328px !important;
}

.checkout-amenities-cont  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    @include respond-to(medium) {
        width: 45%;
    }
}

.checkout-amenities-cont span {
    font-weight: 600;
    font-size: 12px;
    @include respond-to(medium) {
        font-size: 18px;
        text-align: center;
    }
}
.checkout-amenities-cont img {
    width: 65%;
    height: 90px;
    padding: 5px 15px;
}
.checkout-amenities-icons {
    width: 100%;
    // height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    @include respond-to(medium) {
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        grid-gap: 30px;
        justify-content: space-evenly;
    }
}

.checkout-bed-title {
    font-family: SharpGrotesk;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 5px 2px;
}

.checkout-bed-content {
    font-family: SharpGrotesk;
    color: #606060;
    font-size: 11px;
    padding-left: 5px;
}

.checkout-sleep-section {
    margin-bottom: 30px;
    margin-top: 40px;
    position: relative;
    @include respond-to(medium) {
        padding-top: 36px !important;
        background: rgb(232,232,232);
        background: linear-gradient(180deg, rgba(232,232,232,1) 0%, rgba(255,255,255,1) 75%);
    }
}

.widget-arrow-2 {
    position: absolute !important;
    bottom: 20% !important;
    z-index: 99999 !important;
}

.checkout-cards img {
    width: 25%;
    height: 40px;
}

.checkout-cont {
    width: 87%;
    @include respond-to(medium) {
        width: 100%;
    }
}

.widget-checkout-container {
    width: 38%;
    padding-left: 10px;
    @include respond-to(medium) {
        width: 100%;
        padding-left: unset;
    }
}

.checkout-mobile-container {
    @include respond-to(medium) {
        padding: 0px 30px;
        word-wrap: break-word;
    }
}

.checkout-show-all-amenities {
    font-family: SharpGrotesk; 
    font-size: 11px; 
    font-weight: bold;
    @include respond-to(medium) {
        font-size: 15px;
    }
}

.checkout-more-amenities {
    color: #009984; 
    font-size:12px; 
    font-weight: bold;
    @include respond-to(medium) {
        font-size: 16px;
    } 
}

.checkout-view-360 {
    gap: 10px; 
    margin-top: 40px;
    @include respond-to(medium) {
        margin-top: unset;
        font-size: 30px;
    }
}

.checkout-view-360 img {
    @include respond-to(medium) {
        width: 90px;
    }
}

.checkout-reserve-title {
    font-family: SharpSemiBold20;
    color: black;
    font-size: 12px;
    @include respond-to(medium) {
        font-size: 15px;
    }
}

.checkout-reserve-content {
    @include respond-to(medium) {
        font-size: 14px;
        font-family: Arial;
    }
}

.checkout-includes-title {
    font-size: 12px;
    font-family:SharpSemiBold20;
    color: black;
    @include respond-to(medium) {
        font-size: 15px;
    }
}

.checkout-includes-content {
    @include respond-to(medium) {
        font-size: 14px;
        font-family: Arial;
        font-weight:400;
        width: 90%;
        color: black;
        line-height: 1.2;
    }
}

.include-title {
    @include respond-to(medium) {
        color: black;
        // width: 80%;
        font-size: 28px;
    }
}

.include-title-green {
    @include respond-to(medium) {
        color: $greenFont;
        width: 80%;
        font-size: 25px;
    }
}

.include-title-green-100 {
    @include respond-to(medium) {
        color: $greenFont;
        width: 100% !important;
        font-size: 28px;
    }
}

.leadWidget > form.orbirental-capture-widget-form > button {
    @include respond-to(medium) {
        font-size: 17px !important;
        font-weight: bold !important;
    }
}

.checkout-custom-carousel img {
    @include respond-to(medium) {
        width: 100%;
    }
}

.container-top-carousel{


}

.checkoutSmallPic {
    width: 100%;
    padding:2px;
    pointer-events: none;
}


.checkout-custom-carousel {
    height: initial;
    width: 33%;
    @include respond-to(medium) {
        width: 83%;
        height: 262px;
    }
}

.checkout-pagination {
    @include respond-to(medium) {
        
    }
}

.checkout-pagination img {
    @include respond-to(medium) {
        width: 10px;
    }
}

.checkout-modal-container {
    
    @include respond-to(medium) {
        width: 98% !important;
        margin: auto !important;
        padding: 10px !important;
    }
}

.checkout-modal-line {
    width: 70%;
    border-bottom: 1px solid black;
    position: absolute;
    right: 0; 
    margin-bottom: 10px;
    @include respond-to(medium) {
        margin-left: 20px;
    }
}

.ReactModal__Overlay--after-open {
    z-index: 88 !important;
}

.alice-custom-right-btn {
    width:16px;
    position:absolute;
    right:450px;
    bottom:-12px;
    @include respond-to(medium) {
        right:115.5vw;
    }
}

.alice-custom-left-btn {

}

.view-all-propics {
    position: absolute;
    bottom: 10px;
    left: 50%;
    padding: 6px 30px;
    font-family: Georgia;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.381);
    border-radius: 0px 0px 0px 20px;
    font-size: 14px;
    border: 1px solid white;
    color: white;
    z-index: 2;

    @include respond-to(small) {
        left: 28%;
        
    }
}

.prop-main-pic .react-multiple-carousel__arrow {
    margin-bottom: unset;
}

.leadWidget p {
    // color: rgb(255 255 255) !important;
    // position: absolute;
    // top: 19px;
    // font-size: 0.8vw !important;
    // width: 100%;
    @include respond-to(medium) {
        left: 8px !important;
        width: 96% !important;
        // padding-bottom: 10px;
        padding-right: 10px;
        // height: 10px;
    }
}

.orbirental-capture-widget-price {
    color: white !important;
}

.leadWidget > form.orbirental-capture-widget-form .orbirental-capture-widget-price {
    font-family: SharpSemiBold20 !important;
    font-size: 1.7vw !important;
    font-weight: bold !important;
    color: white !important;
    @include respond-to(medium) {
        font-size: 5.7vw !important;
    }
}

#orbirental-capture-widget-details-link {
    // margin-top: 20px !important;
    // margin-right: 15px !important;
    // display: none !important;
    // color: black !important;
}

.orbirental-capture-widget-price-option {
    // color: red !important;
}

.orbirental-capture-guests {
    @include respond-to(medium) {
        // padding: 5px 20px 5px !important;
    }
}

.orbirental-capture-widget-wrapper {
    @include respond-to(medium) {
        // width: 80%;
    }
}

.orbirental-capture-widget-datepicker {
    @include respond-to(medium) {
        // padding: 14px 20px 5px !important;
    }
}

#leadWidget > form > div.orbirental-capture-widget-price-option{
    background-color: black !important;
    border-radius: 6px 0px 0px !important;
}

#leadWidget > form > div.orbirental-capture-widget-totalnights {
    background-color: black;
    width: 100%;
}

#leadWidget > form > div.orbirental-capture-widget-minstay-option {
    padding-bottom: 5px;
}
 
.orbirental-capture-widget-minstay-option p {
    color: white !important;
    position: absolute !important;
    bottom: 0px !important;
    right: 9px !important;
    font-size: 11px !important;
    font-weight: bold !important;
    font-family: SharpBold;
}

#leadWidget > form > div.orbirental-capture-checkin,#leadWidget > form > div.orbirental-capture-checkout {
    margin-top:20px!important;
}
#leadWidget > form > div > input,#leadWidget > form > div > select{
    border-radius: 6px!important;
    margin-bottom: 4px!important;
}



//custom modal full


.react-responsive-modal-overlay{
    background: rgba(0, 0, 0, 0.9)!important;
}
  

.react-responsive-modal-closeButton{
    background: white!important;
    padding: 10px!important;
    border-radius: 100px;
    @include respond-to(medium) {
        padding: 2px!important;
    }
}

.full-carousel-btn-left{
    position: absolute;
    top: 45%;
    left: 10px;
    transform: translateY(-50%);
    background-color: white!important;
    border-radius: 6px !important;
    padding: 6px !important;
}

.full-carousel-arrow-left{
    width: 12px;
}


.full-carousel-btn-right{
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    background-color: white!important;
    border-radius: 6px !important;
    padding: 6px !important;
}

.full-carousel-arrow-right{
    width: 12px;
    
}
 


//custom full screen modal div
.full-div-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .carousel-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .carousel-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Maintains aspect ratio while expanding */
  }
  
  .carousel-button {
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    color: black;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  .close-btn {
    right: 20px;
    top: 20px;
    transform: none;
    font-size: 30px;
  }
  //end of cusom carousel



//Checkout specific contact styles
div.whiteDateRange  div.rs-input-group-inside{
background-color: white!important;
}
div.whiteDateRange input.rs-input{
background-color: white!important;
}
div.whiteDateRange div.rs-picker-daterange.rs-picker-subtle div.rs-input-group{
    background: white!important;
    border-bottom: 1px solid lightgray!important;
}
div.whiteDateRange div.rs-picker-toggle-wrapper{
    border:none!important;
}



// Modal amenities
/* styles.css */
.modalAmenitiesCheckout {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
  }
  
  .modalAmenitiesCheckout {
    width: 45%;
    margin: auto;
    padding: 40px;
    inset: 40px;
  }
  
  /* Media query to handle mobile specific styles */
  @media (max-width: 768px) {
    .modalAmenitiesCheckout {
      width: 95%;
      padding: 20px;
      inset: 10px;
    }
  }


  //Reviews

  .review-body {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .review-stars{
        height: 20px;
        top: 50px;
        // font-family: Georgia;
        font-weight: 600;
        color: #606060;
        text-wrap: balance;
        text-align: center;
    
  }
  .review-person{
    height: 20px;
    top: 50px;
    // font-family: Georgia;
    font-weight: 600;
    color: #606060;
    text-wrap: balance;
    text-align: center;
}

.review-card:nth-child(3n + 2){
    border-right:1px solid gray;
    border-left:1px solid gray;
}