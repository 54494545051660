@import '../styles/breakpoints.scss';
@import '../styles/mixins.scss';


.skeleton {
  width: 46%;
  height: 300px;
  background: #ffff;
  border: 1px solid #e9e9e9;
  padding: 0px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;
  align-items: flex-start;
  border-radius: 10px;
  
    @include respond-to('medium') {
        width: 95%;
    }
}


.skeleton_load_extreme_title {
  width: 95%;
  height: 10px;
  position: relative;
  float: right;
  border-radius: 5px;
  background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
  padding: 8px;
  margin: 10px 0px 10px 10px;
}
.skeleton_load_extreme_title_2 {
  width: 65%;
  height: 10px;
  position: relative;
  float: right;
  border-radius: 5px;
  background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
  padding: 8px;
  margin: 0px 0px 10px 10px;
}
.skeleton_load_extreme_title_3 {
  width: 35%;
  height: 10px;
  position: relative;
  float: right;
  border-radius: 5px;
  background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
  padding: 8px;
  margin: 0px 0px 10px 10px;
}

.skeleton_load_extreme_descripion {
  width: 100%;
  height: 100%;
  position: relative;
  float: right;
  border-radius: 5px;
  background: linear-gradient(120deg, #e5e5e5 30%, #f0f0f0 38%, #f0f0f0 40%, #e5e5e5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
}

@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}

.fav-icon-skeleton-container {
  display: flex;
  justify-content: flex-end;    
}

.fav-icon-skeleton {
  width: 25px !important;
  position: absolute;
  z-index: 1;  
  margin: 5px 10px;
}
