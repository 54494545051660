.carousel {
  width: auto;
  height: auto;
  text-align: center;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  
  
  
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}
.carousel-slide::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.carousel-slide::-webkit-scrollbar-thumb {
  background: #374a49;
  border-radius: 10px;
}
.carousel-slide::-webkit-scrollbar-track {
  background: transparent;
}
.carousel-slide > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 300px;
  height: 500px;
  margin-right: 10px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  
}
.carousel-slide > div:target {
   transform: scale(0.8); 
}
