@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';

.footer-container {
    display: flex;
    flex-direction: column;
    background-color: #DFE1ED;
    padding: 10px;
}

.show-only-mobile {
    display: none;
    @include respond-to(medium) {
        display: block;
    }
}

.black {
    color: black !important;
    font-weight: 500;
}

.social-column {
    display: flex;
    height: fit-content;
    align-items: center;
    @include respond-to(medium) {
        margin: auto;
        padding: 20px 0px 20px 0px;
    }
}

.columns-container {
    display: flex;
    justify-content: space-between;
    width: 82%;
    position: relative;
    margin: auto;
    padding-top: 22px;
    @include respond-to(medium) {
        flex-direction: column;
    }
}

.background-color{
    
    height: auto;
    color: #6F6B8E;
    @include respond-to(medium) {
        background-color: #D0D3DE;
        // padding-top: 120px;
    }
}
.tel{
    color: #6F6B8E;
    padding-right: 10px;
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Nova;
}
.font-1{
    font-size: 16px;
    font-weight: 700;
    font-family: Nova;
    color: #6F6B8E!important;
}
.font-2{
    font-size: 1rem;
    font-weight: 100;
    font-family: Nova;
    color: #6F6B8E!important;
}
.cursor-footer{
    cursor: pointer;
    @include respond-to(medium) {
        text-align: center;
    }
}
.font-2 strong{
    font-size: .9rem;
    font-weight: 100;
    font-family: Nova;
    color: #6F6B8E!important;
    font-weight: 700;
}
.logo-sociales img{
    background-position: center center;
    background-size: contain;
    cursor: pointer;
    @include respond-to(medium) {
        width: 4rem ;
    }
    
}
.logo-tarjetas{
    background-position: center center;
    background-size: contain;
}
.select-button{
    background-color: transparent;
    border-radius: 2rem;
    border: 1px solid #fff;
    color: #6F6B8E;
    padding: 0px 20px;
    font-weight: bold;
    font-size: 13px;
    padding: 0px;
    width: 35%;
    font-family: Nova;
}
.align-center-mobile{
    text-align: center;
}

.background-color .cards img {
    width: 100%;
}
.background-color img {
    @include respond-to(medium) {
        width: 100px;
    }
}



@media (max-width: 768px) {
    .background-color{
        background-position: bottom;
        // height: 600px;
    }
    .select-button{
        background-color: transparent;
        border-radius: 2rem;
        border: 1px solid #fff;
        color: #6F6B8E;
        padding: 0px 20px;
        font-weight: bold;
        /* font-size: 13px; */
        padding: 0px;
        width: 50%;
        font-family: Nova;
    }
}

#greenLogo {
    height: 100px;
    margin-left: 250px;
    /* width: 100%; */
}

.logo-rights {
    display: flex;
    width: 80%;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
    @include respond-to(medium) {
        justify-content: center;
    }
}

.logo-container img {
    width: 70px !important;
    @include respond-to(medium) {
        width: 110px !important;
    }

}
.logo-container {
    @include respond-to(medium) {
        display: flex;
        justify-content: center;
    }
}

.rights-desktop {
    display: block;
    gap: 20px;
    @include respond-to(medium) {
        display: none;
    }   
}
.rights-mobile {
    display: none;
    @include respond-to(medium) {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 20px;
        padding-bottom: 20px;
    }   
}

.nav-element {
    @include respond-to(medium) {
        text-align: center;
        font-size: 18px;
    }
}

.contact-section {
    @include respond-to(medium) {
        display: none;
    }
}

.contact-section-mobile {
    display: none;
    @include respond-to(medium) {
        padding-top: 10px;
        display: block;
        text-align: center;
    }
}

.credit-cards img {
    width: 83%;
    @include respond-to(medium) {
        width: 90%;
    }
}
.credit-cards {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -60px;
    @include respond-to(medium) {
        width: 100%;
        position:static;
        display: flex;
        justify-content: center;
        margin: auto;
        padding-bottom: 20px;
    }
}

.lang-section {
    @include respond-to(medium) {
        text-align: center;
        padding: 10px;
    }
}

.lang-sect {
    color: white;
    width: 33px;
    display: flex;
    justify-content: center;
}

.lang-sect-footer {
    color: white;
    width: 33px;
    display: flex;
    justify-content: center;
    
}

.contact-modal-mobile {
    display: block;
    @include respond-to(medium) {
        display: none;
    }
}

.show-only-desktop {
    display: contents;
    @include respond-to(medium) {
        display: none;
    }
}

.whatsapp-fixed {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 30;

    @include respond-to(medium) {
        bottom: 20px;
        right: 10px;
    }
}
