@import '../../../Views/styles/breakpoints.scss';
@import '../../../Views/styles/mixins.scss';
@import '../../../../src/Views/styles/variables.scss';

.card {
    width: 100%;
    // height: 200px;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    border: none !important;
    transition: 0.6s;
    margin: 10px;
    border-radius: 15px;
    @include respond-to(medium) {
      margin: 0px;
      width: 100%;
    }
  }

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
}

.prop-img {
  pointer-events: none;
}
  
.price-container {
  position: absolute;
  bottom: -10px;
  left: 0;
  padding: 10px 0px 20px 6%;
  display: flex;
  flex-direction: column;
  @include respond-to(medium) {
    bottom: -20px;
    margin-left: 15px;
  }

}
  .price {
    color: rgba(255, 255, 255, 0.79) !important;
    // font-weight: bold;
    margin-bottom: -9px;
    font-size: 9px;
    @include respond-to(medium) {
      font-size: 12px;
      font-weight: 400;
    }
  }
  
  .price-usd-container {
    display: flex;
    align-items: baseline;
  }

  .price-usd {
    color: white !important;
    font-weight: 500;
    font-size: 16px;
    font-family: NovaBlack;
    @include respond-to(medium) {
      font-size: 20px;
    }
  }
  .price-usd-value {
    color: white !important;
    font-weight: bold;
    font-size: 1.8rem !important;
    font-family: NovaBlack;
    @include respond-to(medium) {
      font-size: 20px;
    }
  }
  
  .prop-img {
    width: 100%;
  }

  .react-multi-carousel-item {
    // width: 300px !important;
    // pointer-events: none;
  }

  .property-name {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0px 0px 10px;
    width: 100%;
    color: $greenCarousel !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    @include respond-to(medium) {
      font-size: 17px;
    }
  }
  .property-subTitle {
    font-size: 12px;
    // font-weight: bold;
    padding: 0px 10px;
    width: 100%;
    color: grey !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    @include respond-to(medium) {
      font-size: 17px;
    }
  }
  .property-name2 {
    font-size: 15px;
    font-weight: bold;
    // padding: 10px 0px 0px 10px;
    width: 100%;
    color: $greenCarousel !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    @include respond-to(medium) {
      font-size: 17px;
    }
  }
  .property-subTitle2 {
    font-size: 12px;
    // font-weight: bold;
    // padding: 0px 10px;
    width: 100%;
    color: grey !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    @include respond-to(medium) {
      font-size: 13px;
    }
  }

  .img-cont {
    position: relative;
  }
  .carousel-dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    aspect-ratio: 1;
    background-color: #A9A9A9;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.6s;
    // width: 70%;
  }
  
  .carousel-dot.active {
    background-color: $welcome-green;
    width: 15px;
    height: 15px;
      border-radius: 100%;
  }

  .carousel-dots {
    display: flex;
    width: 40%;
    @include respond-to(medium) {
        // width: 60%;
        margin: auto;
    }
  }

  .carousel-slider-navigation {
    position: absolute;
    bottom: 13%;
    left: 58%;
    align-items: baseline;
    transform: translateY(1480%) translateX(26%);
    width: 55%;
    @include respond-to(medium) {
      width: 100%;
      left: -54px;
      bottom: 61%;
    }
}