.checkout-alt-carousel-container {
    position: relative;
    width: 100%;
    margin-bottom: 70px;
    /* overflow: hidden; */
  }
  
  .checkout-alt-carousel-wrapper {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .checkout-alt-carousel {
    display: flex;
  }
  
  .checkout-alt-carousel img {
    width: 100%;
    padding: 5px;
    flex-shrink: 0;
  }
  
  .checkout-alt-prev, .checkout-alt-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    outline: none;
    font-size: 20px;
  }
  
  .checkout-alt-prev {
    right: 57px;
    position: absolute;
    top: -33px;
  }
  
  .checkout-alt-next {
    right: 0;
    position: absolute;
    top: -33px;
  }
  