// $main-font:'Roboto', sans-serif;

$medium-grey: #a8a8a8;
$light-grey: #F2F2F2;
$dark-grey: #313537;
$common-blue: #0078A9;
$light-blue: #D6DEEE;
$dark-blue: #173654;
$color-error: #f53c3ce6;
$color-success: #33973b;

$welcome-green: #009F8A;
$greenFont : #0F4749;
$maxWidth: 1380px;
$greyFont: #606060;
$greenCarousel : #1B6765;
$lightGrey : #A9A9A9;

$columnWidth : 75%;

$notBlack : #001109;