@import '../../../Views/styles/breakpoints.scss';
@import '../../../Views/styles/mixins.scss';

.skeleton-container {
    display:"flex";
    flex-direction:"row";
    flex-wrap: "wrap";
    position: absolute;
    top: 200px;
    width: 99%;
    @include respond-to(medium) {
      position: initial;
      top: unset;
      width: 100%;
    }
  }