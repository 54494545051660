@import '../../../src/Views/styles/breakpoints.scss';
@import '../../../src/Views/styles/mixins.scss';
@import '../../../src/Views/styles/variables.scss';

.partners-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-stretch-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: stretch !important;
}

.slider-no-margin {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin-top: 30px;
}

.slider h4 {
  // font-family: Arial, Helvetica, sans-serif;
}

.slider-slide {
  min-width: 100%;
  box-sizing: border-box;
  padding: 25px 0% 10px 0%;
}

.slider-card {
  padding: 10px 9px;
  // height: 400px;
  display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    align-content: center;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slider-card-phrases {
  @include respond-to(medium) {
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    align-content: center;
  }

}

.title-content {
  // font-size: 40px;
  padding: 10px 0px 10px 0px;
}

.text-content {
  // color: grey;
  // line-height: 30px;
  // font-size: 14px;
  line-height: 1.8;
  color: #c5c5c5;
  font-family: SharpGrotesk;
  font-size: 16px;
  @include respond-to(medium) {
    font-size: 13px;
    color: #929292;
    // font-size: bold;
    font-family: SharpGrotesk;
    line-height: 2;
  }
}

.slider img {
  width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  user-select: none;
}

.slider-navigation {
  position: absolute;
  top: 25px;
  z-index: 9;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  transform: translateY(-50%);
  width: 60%;
  @include respond-to(medium) {
    left: 13px;
  }
}

.slider-navigation button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
}

.dots {
  display: flex;
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: $welcome-green;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.6s;
  width: 70%;
  @include respond-to(medium) {
    height: 7px;
  }
}

.dot.active {
  background-color: #aeaeae;
  width: 100%;
    border-radius: 5px;
}

.slider-buttons button {
  background-color: transparent;
}
.slider-buttons {
  // font-size: 40px;
  position: absolute;
  bottom: -54px;
  width: 40%;
  left: 28%;
  display: flex;
  justify-content: space-evenly;
  @include respond-to(medium) {
    left: 30%;
    bottom: -74px;
  }
}