@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';

:root {
  --servicios-title-f-size: 1.313rem;
  --servicios-body-f-size: 1rem;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #374a49;
  margin-right: 20em;
}

input[type=date]::-webkit-datetime-edit {
  color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

// input.no-placeholder[type="date"]:before {
//   content: none !important;
//   margin-right: 0em;
// }

// input[type="date"]:focus:before,
// input[type="date"]:valid:before {
//   content: "";
// }

.btnSubmit {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  font-family: NovaBlack;
  border-radius: 2em;
  color: white;
  border: transparent;
  font-size: .8rem;
  font-weight: 500 !important;
  padding: var(--extraPadding-btn-action) !important;
  position: absolute;
  bottom: 10%;
  letter-spacing: .05rem !important;
}

.b-no-repeat {
  background-repeat: no-repeat;
}

.pics-home-radius {
  border-radius: 4.5rem;
}

.background-hospitality {
  background-image: url('../../assets/7. BACKS/Recurso\ 7-8.png');
  background-size: initial;
  overflow: hidden;
  background-repeat: no-repeat;
  /*background-position: 14px -5vw;
  height: 35vw;*/
}

.background-alguila {
  background-image: url('../../assets/7. BACKS/Recurso\ 5-8.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
 

.overlay-webm {
  width: 100%;
    height: 1086px;
    position: absolute;
    z-index: 4;
    background: #00000087;
}
 
.background-image-search {
  background-image: url('../../assets/1. INICIO/banner1.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 500px;
  padding-bottom: 10%;
  height: 700px;
}

.video_background {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.carousel-skeleton-container {
  @include respond-to(medium) {
    width: 100%;
    
  }
}

.prop-container {
  width: $columnWidth;
  max-width: $maxWidth;
  margin: auto;
  // height: 450px;
  @include respond-to(medium) {
    width: 80%;
  }
}

.properties-slider-container {
  // width:80%; 
  position:'relative';
  margin: auto;
  @include respond-to('medium') {
    width:99%; 
    // padding: 0px 32px 0px 32px;
  }
}

.viajeros-cont {
  padding: 40px 0px 18px;
  margin-left: 10px;
  width: 592px;
  @include respond-to(medium) {
    padding: 40px 0px;
    width: unset;
    margin-left: unset;
  }
}

.regular-slider-container {
  margin: 'auto';
  @include respond-to(medium) {
    width: 100%;
  }
}

.background-Viajeros {
  // background-color: #374a49;


  @include respond-to('medium') {

  }
}

.containerBtnViajeros {
  width: 100%;
    position: absolute;
    bottom: 10px;
    left: 32px;
    color: white;
}

.contacto-span {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  color: #fff !important;
  padding: 7px 4px 7px;
  cursor: pointer;
  border-radius: 5em 0em 0em 5em;
  position: absolute;
  bottom: 2vw;
  height: var(--btn-height);
  right: .8%;
  padding: 6px 18px;
}

.contacto-texto {
  font-size: 0.6rem;
  width: 100px;
  margin-left: 25px
}

.contacto-texto strong {
  font-family: NovaBlack;
}

.contacto-title {
  font-size: .7rem;
  font-family: Nova;
  letter-spacing: .0125em !important;
  display: inline-block
}

.title-home {
  width: 600px;
  margin-left: 150px;
  margin-top: -150px;
  margin-bottom: 150px;
  justify-content: center;
  font-size: var(--title-f-size);
  font-family: NovaBlack;
  letter-spacing: .0125em !important;
  line-height: 1.2em !important;
  color: white;
  
}

div.subTitle-home {
  justify-content: center;
  font-size: var(--subTitle-f-size);
  font-family: Nova;
  letter-spacing: .0125em !important;
  line-height: 1.2em !important;
  color: white;

  @include respond-to('small') {
    padding: 20px;
    text-align: center;
    margin-bottom: 0px !important;
  }
}

.input-zona-icon {
  background-image: url('../../assets/1. INICIO/4.svg');
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 15px;
}

.input-calendar-icon {
  background-image: url('../../assets/1. INICIO/3.svg');
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 18px;
}

.input-huesp-icon {
  background-image: url('../../assets/1. INICIO/5.svg');
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 20px;
}

.input-location-icon {
  background-image: url('../../assets/1. INICIO/location.svg');
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 15px;
}

.select-filter {
  appearance: none !important;
}

.inputs-home {
  background-color: #fff !important;
  border-radius: 3rem;
  border: 1px solid #ced4da !important;
  padding: 15px 30px;
  width: 250px;
  cursor: pointer;
  font-family: Nova;
  font-size: var(--input-f-size);
  color: #374a49;
  text-align: left;
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include respond-to('small') {
    margin: 0px !important;
    padding: 0px;
  }
}

.scroller-textos {
  background-color: transparent;
  font-weight: 900;
  color: #000;
}

.col2-hospi {
  padding: 0 32px 0 80px;
}

.title-texto {
  font-size: 14px;
  text-align: left;
  line-height: 1.2rem;
  color: #374a49 !important;
  font-family: Georgia;
  font-weight: bold;
  padding: 10px 0px;
  @include respond-to('small') {
    width: 100%;
  }
}

.cuerpo-texto {
  font-weight: 500;
  text-align: left;
  line-height: 1.1em;
  font-size: var(--servicios-body-f-size) !important;
  color: #374a49 !important;
  font-family: Nova;
  line-height: 1.25em !important;
}

.titulo-hospitalidad {
  text-align: left !important;
  font-size: 2.4rem !important;
  line-height: 2.2rem;
  color: #374a49 !important;
  font-family: NovaBlack;
}

.imgHospitalidad {
  position: absolute;
  right: -20vw;
  width: auto;
}

.texto-hospitalidad {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.2em;
  text-align: left !important;
  color: #374a49 !important;
  font-family: Nova;
  letter-spacing: var(--letter-spacing);
}

.frase-hospitalidad {
  color: #374a49 !important;
  font-weight: 750;
  line-height: 1.2em;
  font-size: 22px !important;
  font-family: Nova;
  text-align: left;
}

.b-radius-neigh {
  border-radius: 2rem;
}

.btnW2ba {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  font-family: NovaBlack;
  border-radius: 2em;
  color: white;
  border: transparent;
  font-size: .9rem;
  letter-spacing: var(--letter-spacing);
  font-weight: 500 !important;
  padding: 15px 60px;
}

.btnW2ba-search .material-symbols-outlined {
  font-size: 20px !important;
  @include respond-to(medium) {
    font-size: 29px !important;
  }
}

.btnW2ba-search-new {
  background-color: #20b19d;
  color: white;
  padding: 7px 23px;
  border-radius: 10px 0px 0px 10px;
  border-right: 1px solid white;
  font-family: Georgia;
  font-weight: bold;
  font-size: 13px;
}
.btnW2ba-clear-new {
  background-color:black;
  color: white;
  padding: 7px 23px;
  border-radius: 0px 10px 10px 0px;
  font-family: Georgia;
  font-weight: bold;
  font-size: 13px;
}

.btnW2ba-search {
  background-color: #EC9601;
  font-family: NovaBlack;
  color: white;
  border: transparent;
  font-size: .75rem;
  letter-spacing: var(--letter-spacing);
  font-weight: 500 !important;
  transition: 0.4s;
  padding: 20px;
  display: flex;
  align-items: center;
  width: 70px;
  height: 70px;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-to('small') {
    height: 55px;
  }

  @include respond-to(medium) {
    width: 100%;
    height: 40px;
    margin-top: 20px;
  }
}

.btnW2ba-alquila {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  font-family: Nova;
  border-radius: 2em;
  color: white;
  border: transparent;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: var(--letter-spacing);
  padding: 15px 60px;
}

.btnW2ba-search:hover img {
  // box-shadow: 0 0 15px rgba(32, 177, 157);
  transform: scale(1.2);
  transition: 0.2s ease-in-out;
}

.btnW2ba:hover {
  box-shadow: 0 0 15px rgba(32, 177, 157);
}

.btnW2ba-alquila:hover {
  box-shadow: 0 0 15px rgba(32, 177, 157);
}

.button-mas-home {
  font-family: Nova;
  border-radius: 3rem;
  border-color: white;
  border-style: solid;
  color: white;
  width: 70%;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 400 !important;
  padding: 15px 0px 15px 0px;
}

.title-barrios {
  color: #374a49 !important;
  font-size: 2.5rem !important;
  font-weight: 800;
  font-family: NovaBlack;
  letter-spacing: var(--letter-spacing);
}

.end-image-footer {
  background-color: rgb(167, 188, 205);
  background-size: contain;
  height: 12px;
}

.titulo-alquila {
  font-size: 2.3rem !important;
  color: #374a49 !important;
  font-family: NovaBlack;
  margin-left: 70px;
  letter-spacing: var(--letter-spacing);
}

.subtitulo-alquila {
  font-size: 1.1rem;
  color: #20b19d;
  font-family: NovaBlack;
  letter-spacing: var(--letter-spacing);
}

.margin-subtitulo-alquila {
  margin-left: 70px;
}

.title-alquila {
  font-size: .9rem !important;
  line-height: 1.2em !important;
  font-weight: 500;
  font-family: Nova;
  letter-spacing: var(--letter-spacing);
}

.texto-alquila {
  letter-spacing: var(--letter-spacing);
  line-height: 1.2rem !important;
  font-size: .9rem;
  color: #374a49 !important;
  font-family: Nova;
}

.texto-alquila-2 {
  font-size: .9rem;
  padding: 0 2rem 1rem 0;
  color: #20b19d;
  font-family: NovaBlack;
  letter-spacing: var(--letter-spacing);
}

.texto-servicio {
  font-size: .8rem;
  line-height: 1.2rem;
  font-family: Nova;
  color: #374a49 !important;
}

.img-alquila {
  background-position: center;
  background-size: contain;
}

.img-alquila-2 {
  background-position: center;
  background-size: cover;
  margin-bottom: 0;
  margin-top: 0;
}

.diseno-margin {
  margin-bottom: 50px;
  margin-top: 50px;
}

.texto-price {
  background-color: #009f8b;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px 5px;
  cursor: pointer;
}

.text-barrio-title {
  position: absolute;
  margin-left: 4em;
  margin-top: 1em;
  font-size: 40px !important;
  font-family: Nova;
  font-weight: 700;
  letter-spacing: .0125em !important;
  color: white;
  align-content: center;
}

.text-pics {
  font-family: Nova;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.button-pics {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  font-family: Nova;
  border-radius: 2rem;
  color: white;
  border: transparent;
  font-size: .8rem;
  font-weight: 400;
  padding: 5px 30px;
}

.button-pics:hover {
  box-shadow: 0 0 15px rgba(32, 177, 157);
}

.pics-alquila {
  border-radius: 5rem;
}

.button-price-barrio {
  background-image: linear-gradient(to right, #57b4a4, #20b19d);
  font-family: Nova;
  border-radius: 2em;
  color: white;
  border: transparent;
  font-size: .9rem;
  font-weight: 500 !important;
  padding-left: 30px;
  padding-right: 15px;
}

.title2-viajeros {
  text-align: center;
  color: #fff;
  font-size: .8rem !important;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: var(--letter-spacing);
  font-family: Nova;
}

.title3-viajeros {
  text-align: center;
  color: #fff;
  font-size: 1.5rem !important;
  line-height: 2rem;
  letter-spacing: var(--letter-spacing);
  font-family: NovaBlack;
}

.card-home {
  background-color: #fff;
  padding: 7px;
  border: transparent;
  text-align: center;
  position: relative;
}
 
.linea-card-home {
  border-bottom: 1px solid #009f8b;
  display: inline-block;
  clear: both;
  float: none;
  width: 90%;
  overflow: visible;
  vertical-align: middle;
  text-align: center;
  line-height: normal;
}

.img-scroller {
  text-align: left;
}

.align-left-browser-button {
  text-align: left;
}

.title-name-home {
  color: #20b19d;
  font-size: 1rem;
  font-family: NovaBlack;
  line-height: 1.2rem;
  letter-spacing: var(--letter-spacing);
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.city-home {
  color: #374a49 !important;
  font-size: 1rem;
  font-weight: 900;
  font-family: Nova;
  line-height: 1rem;
  letter-spacing: var(--letter-spacing);
}

.type-text-home {
  color: #374a49 !important;
  font-size: .8rem;
  font-weight: 400;
  font-family: Nova;
}

.center-icons {
  display: flex;
  justify-content: center;
}

/*mobile width*/
@media (max-width: 768px) {
  .background-hospitality-mobile {
    background-image: url('../../assets/7. BACKS/Recurso\ 7-8.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
 

  .subtitle-selection {
    display: block; 
    width: 553px;
    font-family: SharpGrotesk;
    @include respond-to(medium) {
      width: 100%;
    }
  }

  .title-barrios {
    color: #374a49 !important;
    font-size: 2rem !important;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
  }

  .type-text-home-mobile {
    color: #374a49 !important;
    font-size: .7rem;
    font-weight: 400;
    font-family: Nova;
  }

  .title-name-home-mobile {
    color: #20b19d;
    font-size: 1rem;
    font-family: NovaBlack;
    line-height: 2rem;
    letter-spacing: var(--letter-spacing);
  }

  .city-home-mobile {
    color: #374a49 !important;
    font-size: 1rem;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
  }
 
  .titulo-hospitalidad {
    text-align: left !important;
    font-size: 2.2rem !important;
    line-height: 2.2rem;
    color: #374a49 !important;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
  }

  .texto-hospitalidad {
    font-size: 1rem !important;
    font-weight: 500;
    line-height: 1.2em;
    text-align: left !important;
    color: #374a49 !important;
    font-family: Nova;
    letter-spacing: var(--letter-spacing);
  }

  .frase-hospitalidad {
    color: #374a49 !important;
    line-height: 2rem;
    font-size: 1.8rem !important;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
  }

  .img-scroller {
    text-align: center;
  }

  .title2-viajeros {
    color: #fff;
    font-size: .8rem !important;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
  }

  .title3-viajeros {
    color: #fff;
    font-size: 1.6rem !important;
    line-height: 2rem;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
  }

  .cuerpo-texto {
    text-align: center;
  }

  .align-center-mobile {
    text-align: center !important;
  }

  .background-Viajeros {
    height: auto;
    padding-bottom: 10px;
  }
 

  .input-calendar-icon {
    background-image: url('../../assets/1. INICIO/3.svg');
    background-repeat: no-repeat;
    background-position: 83% center;
    background-size: 18px;
  }
 
  .background-mobile {
    background-image: url('../../assets/1. INICIO/1Mobile.jpg');
    background-size: cover;
    height: auto;
    padding-top: 7%;
    padding-bottom: 10%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .input-zona-icon {
    background-image: url('../../assets/1. INICIO/4.svg');
    background-repeat: no-repeat;
    background-position: 83% center;
    background-size: 15px;
  }
 
  .pics-home-radius {
    border-radius: 2rem;
  }
 
  .btnSubmit-mobile {
    background-image: linear-gradient(to right, #57b4a4, #20b19d);
    font-family: NovaBlack;
    border-radius: 2em;
    color: white;
    border: transparent;
    font-size: var(--input-f-size);
    font-weight: 500 !important;
    padding: var(--extraPadding-btn-action);
    letter-spacing: .05rem !important;
  }

  .btnSubmit-mobile:hover {
    box-shadow: 0 0 15px rgba(32, 177, 157);
  }

  .titulo-alquila-mobile {
    font-size: 2.2rem !important;
    color: #374a49 !important;
    text-align: left;
    margin-left: 10px;
    letter-spacing: var(--letter-spacing);
    font-family: NovaBlack;
  }

  .subtitulo-alquila {
    font-size: 1.4rem;
    color: #20b19d;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
  }

  .texto-alquila {
    letter-spacing: var(--letter-spacing);
    line-height: 1.2rem !important;
    font-size: 1rem;
    color: #374a49 !important;
    font-family: Nova;
  }

  .texto-alquila-2 {
    font-size: 1rem;
    color: #20b19d;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2rem !important;
  }

  .margin-subtitulo-alquila {
    margin-left: 10px;
  }

  .margin-left-mobile {
    margin-left: 20px
  }

  .button-price-viajero {
    // background-image: linear-gradient(to right, #57b4a4, #20b19d);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border-color: transparent;
    font-size: .8rem;
    padding-left: 30px;
    padding-right: 15px;
  }

  .title-home {
    justify-content: center;
    font-size: 2.2rem;
    font-family: NovaBlack;
    letter-spacing: .0125em !important;
    line-height: 1.2em !important;
    color: white;
    text-align: center;
    
  }

  .subtitle-home {
    justify-content: center;
    font-size: 1.2rem;
    font-family: Nova;
    letter-spacing: .0125em !important;
    line-height: 1.2rem !important;
    color: white;
  }

  .button-mas-home {
    font-family: Nova;
    border-radius: 3rem;
    border-color: white;
    border-style: solid;
    color: white;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500 !important;
    width: 60%;
  }

  .col2-hospi {
    padding: 0 0 0 0;
  }
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 20px !important;
}

.homeExpertsIcons .title-texto {
  font-family: NovaBlack;
}

.filter-container-box-video {
  width: 100%;
  position: absolute;
  top: 45%;
  color: white;
}


.filter-container-box-alt {
  width: 100%;
  position: absolute;
  top: 51%;
}

.filter-container-box {
  width: 100%;
  position: absolute;
  top: 69vh;
  @include respond-to(medium) {
    top: 0px;
  }
}

.filter-container-box-dark {
  width: 100%;
  position: absolute;
  top: 93%;
  background-color: grey;
  
}

.green-bar-mobile {
  @include respond-to(medium) {
    height: 100px;
    background-color: #00A995;
  }
}

.filter-map-container {
  position: relative;
  width: 55%;
  // padding: 20px;
  @include respond-to(medium) {
    width: 100%;
    margin-top: 244px;
    padding: 17px;
  }
}

.map-filter-cont {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 96;
  margin-left: -5px;
  @include respond-to(medium) {
    margin-top: -180px !important;
    background-color: rgb(227, 227, 227);
    padding: 20px 16px;
    position: absolute;
    margin-left: unset;
    z-index: 555;
    width: 85%;
    left: 7%;
    top: -194px;
    border-radius: 5px;
  }
}
.map-filter-cont-home {
  @include respond-to(medium) {
    margin-top: -180px !important;
    background-color: rgb(227, 227, 227);
    padding: 8px 16px;
    position: absolute;
    z-index: 555;
    width: 85%;
    left: 7%;
    top: 100px;
  }
}



.form-label {
  font-weight: bold;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 14px;
  margin: 0px;
  @include respond-to(medium) {
    font-size: 20px;
  }
}

// @include respond-to(medium) {
//   .filter-container-box {
//     width: 100%;
//     top: 41%;
//   }

//   .search-home-clean{
//     max-width:fit-content;
//   }
// }

.no-padding-box {
  padding: 4px;
}

.search-home-clean {
  color: white;
  box-shadow: 0 36px 94px rgb(1 111 129 / 17%), 0 8.04107px 20.9961px rgb(1 111 129 / 10%), 0 2.39404px 6.2511px rgb(1 111 129 / 7%);
  width: 63%;
  margin: auto;
  max-width: 1080px;

  @include respond-to('small') {
    width: 95%;
    margin: auto;
  }
}
.search-home {
  background: white;
  /* position: absolute;
  right: 12%;
  bottom: -100px; */
  // min-height: 190px;
  // border-radius: 2rem;
  box-shadow: 0 36px 94px rgb(1 111 129 / 17%), 0 8.04107px 20.9961px rgb(1 111 129 / 10%), 0 2.39404px 6.2511px rgb(1 111 129 / 7%);
  width: 95%;
  margin: auto;
  // padding: 7px;
  max-width: 1080px;

  @include respond-to('small') {
    width: 95%;
    margin: auto;
  }
}

.search-home>div.row {
  min-height: 200px;
}

.mt-11rem {
  margin-top: 11rem !important;
}

.miniCardHover:hover {
  transition: 0.5s;
}

.miniCardHover:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
}

.properties-slider-container h2 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #0C484A;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 45px;
  @include respond-to(medium) {
    font-size: 7.3vw;
  }
}
.properties-slider-container span {
  color: $greyFont;
  font-size: 14px;
  @include respond-to(medium) {
    font-size: 3.6vw;
  }
}

.regular-carousel-container {
  margin-top: 50px;
  @include respond-to(medium) {
    display: none;
  }
}
.regular-mobile-carousel-container {
  display: none;
  user-select: none;

  @include respond-to(medium) {
    margin-top: 0px;
  }
}

.filter-box-desktop {
  display: inline;
  @include respond-to(medium) {
    display: none;
  }
}

.properties-container {
  // height: 240px;
  background-color: transparent;
  // width: 100vw;
@include respond-to(medium) {
  height: 400px;
  width: 95%;
}
}

.loader-container {
  margin: auto;
  width: 0;
  position: absolute;
  /* margin-top: 100px; */
  top: -39px;
  left: 10%;
  z-index: 99999;
}

.lupa-icon {
  width: 17px;
}

.filter-tag {
  padding: 5px 10px;
  border: 1px solid lightgrey;
  border-radius: 0px 0px 0px 11px;
  font-size: 10px;
  display: flex;
  margin-top: 10px;
  grid-gap: 15px;
  gap: 15px;
  height: 29px;
  font-family: SharpBold;
  align-items: center;
}

.filter-tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  @include respond-to(medium) {
    // margin-left: 15px;
    padding: 10px 0px;
  }
}


.prop-text {
  font-family: Nova;
  font-weight: bold;
  font-size: 15px;
  color: black;
}

.info-results-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond-to(medium) {
    // margin-top: 111px;
  }
}

.fav-container {
  @include respond-to(medium) {
    display: none;
  }
}


.filters-btns {
  display: flex;
  align-items: center;
  @include respond-to(medium) {
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.order-by-props hr {
  width: 80%;
  border-bottom: 1px solid #a9a9a9;
  margin-left: 20px;
}

.order-by-props {
  width: 143px;
  @include respond-to(medium) {
    display: none;
  }
}
.order-by-props-mobile {
  display: none;
  width: 143px;
  @include respond-to(medium) {
    display: block;
    margin-top: 11px;
  }
}

.filter-orders-cont-mobile {
  @include respond-to(medium) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.css-b62m3t-container {
  height: 17px !important;
  @include respond-to(medium) {
    height: unset !important;
  }
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.hide-in-mobile {
  @include respond-to(medium) {
    display: none;
  }
}

.css-1vvoc4w-control {
  height: 39px !important;
}

.css-5xf2nx-control {
  height: 39px !important;
  border-bottom: 1px solid black;
}

.filter-my-date-range-picker-clean .rs-input {
  background: rgb(227, 227, 227) !important;
  font-size: 14px !important;
}


.rs-picker-daterange.rs-picker-subtle .rs-input-group{
  background: #E3E3E3 !important;
}


.filter-box-desktop .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
  background: white !important;
}
.filter-box-desktop .rs-picker-daterange.rs-picker-subtle .rs-input-group{
  background: white !important;
}

.checkout-cards {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.checkout-cards img {
  max-width: 100%; /* Las imágenes ocuparán el 50% del ancho del contenedor padre */
  height: auto; /* Para mantener la proporción de aspecto */
  box-sizing: border-box; /* Esto asegurará que el padding y el border no afecten al tamaño total de las imágenes */
  padding: 5px; /* Espacio entre las imágenes */
  object-fit: contain; /* Para que las imágenes se ajusten al contenedor sin ser recortadas */

}

.css-qzh6eo-singleValue {
  // color: yellow !important;
  font-family: Arial;
  font-size: 14px;
  color: rgb(162, 162, 162) !important;
}

.custom-select__control {
  @include respond-to(medium) {
    font-size: 17px !important; // Ajusta el tamaño de fuente según sea necesario
    transform: scale(1);
  }
}

.dropdown-select__control.dropdown-select__control--is-focused {
  font-size: 16px !important;
}

.sticky-filter {
  height: 200%;
  @include respond-to(medium) {
    
  }
}
