@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';

.wt-header-container {
    display: flex;
    width: 100%;
    padding-top: 80px;
    @include respond-to(medium) {
        flex-direction: column;
    }   
}

.wt-header-texts {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 100px 0px 15%;
    gap: 10px;
    background-color: #66B1AF;
    @include respond-to(medium) {
        width: 100%;
        padding: 43px;
        padding-top: 61px;
        padding-bottom: 70px;
    }
}

.wt-header-texts span {
    font-family: SharpGrotesk;
    color: #001109;
    font-size: clamp(10px, 0.9vw, 20px);
    line-height: 2;
    @include respond-to(medium) {
        color: #384240;
        font-size:3vw;
        line-height: 1.7;
        margin-top: 10px;
        padding-bottom: 10px;
    }
}

.wt-header-texts h3 {
    font-family: Georgia;
    font-size: clamp(20px, 2.4vw, 50px);
    font-weight: bold;
    color: #001109;
    width: 22.6vw;
    @include respond-to(medium) {
        width: 77vw;
        font-size: 8.14vw;
        color: #0F4749;
    }
}

.wt-header-img {
    width: 50%;
    @include respond-to(medium) {
        width: 100%;
    }
}

.wt-header-img img {
    width: 100%;
}

.wt-body-container {
    margin-left: 15%;
    margin-right: 16%;
    // height: 500px;
    margin-top: 60px;
    @include respond-to(medium) {
        margin-top: 46px;
        margin-left: unset;
        margin-right: unset;
        text-align: left;
        position: relative;
        // background-color: #D9D9D9;
    }
}

.wt-body-mobile-background {
    @include respond-to(medium) {
        background-color: #D9D9D9;
        position: absolute;
        top: -47px;
        width: 100%;
        height: 697px;
        z-index: -1;    
    }
}

.wt-body-container h3 {
    font-family: Georgia;
    color: #0F4749;
    margin-bottom: 34px;
    font-size: clamp(20px, 2.4vw, 50px);
    text-align: center;
    font-weight: bold;
    @include respond-to(medium) {
        text-align: left;
        padding-left: 21px;
        color: #0F4749;
        margin-bottom: 30px;
        font-size: 7.3vw;
    }
}

.wt-data-container {
    display: unset;
    flex-direction: unset;
    @include respond-to(medium) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.wt-imgs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
}

.wt-imgs-container img {
    width: 100%;   
}

.wt-icons-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 89px 8%;
    @include respond-to(medium) {
        padding: 24px 7%;
        flex-wrap: wrap;
        width: 100%;
    }
}

.wt-img-carousel {
    @include respond-to(medium) {
        width: 100%;
        margin: 8px;
        padding: 8px;
    }
}

.wt-icons-container img {
    width: 77px;
    height: 68px;
    @include respond-to(medium) {
        width: 56%;
        height: unset;
    }
}

.wt-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: SharpBold;
    font-weight: bold;
    font-size: 15px;
    gap: 10px;
    @include respond-to(medium) {
        font-size: 3.4vw;
        width: 40%;   
    }
}

.wt-contact-container {
    display: flex;
    height: 490px;
    margin-bottom: 81px;
    width: 100%;
    @include respond-to(medium) {
        flex-wrap: wrap;
        height: unset;
    }
}

.wt-conctact-text {
    // background-color: red;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding: 1px 10px;
    @include respond-to(medium) {
        width: 100%;
    }
}

.wt-conctact-text h3 {
    font-family: Georgia;
    color: #001109;
    font-size: 2.6vw;
    @include respond-to(medium) {
        font-size: 7.6vw;
        margin-top: 34px;
    }
}

.wt-conctact-text span {
    font-size: 1vw;
    line-height: 1.8;
    font-weight: 200;
    width: 70%;
    @include respond-to(medium) {
        width: 80%;
        font-size: 3.4vw;
    }
}

.wt-contact-text {
    margin-top: 0px;
    margin-bottom: 70px;
    @include respond-to(medium) {
        margin-left: 24px;
        margin-top: 0px !important;
        margin-bottom: 30px;
    }
}


.wt-contact-tip {
    display: flex;
    width: 100%;
    gap: 10px;
}

.wt-contact-tip img {
    width: 13px;
}

.wt-contact-tip span {
    font-size: 1vw;
    width: 100%;
    font-weight: bold;
    @include respond-to(medium) {
        font-size: 3.4vw;
    }
}

.wt-dots span {
    width: 10px;
    font-size: 2px;
}

.wt-contact-form {
    position: relative;
    width: 50%;
    @include respond-to(medium) {
        width: 100%;
        padding: 30px;
    }
}

.wt-contact-overlap-form {
    width: 90%;
    z-index: 1;
    right: 0;
    position: absolute;
    height: 136%;
    display: flex;
    top: 0;
    box-shadow: -5px -5px 5px rgba(0, 0, 0, 0), -5px -5px 5px rgba(0, 0, 0, 0.41);
    background-color: white;
    padding: 40px;
    grid-gap: 10px;
    gap: 30px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.wt-brands-container {
    height: fit-content;
    width: 100%;
    position: relative;
    background-color: black;
    padding-bottom: 60px;
    @include respond-to(medium) {
        padding-left: 10px;
    }
}

.wt-contact-overlap-form input {
    border: none;
    border-bottom: 1px solid black;
    padding: 7px 0px;
    width: 90%;
    color: grey;
    font-size: 14px;
    padding-left: 5px;
}

.wt-contact-overlap-form textarea {
    border: none;
    border-bottom: 1px solid black;
    padding: 7px 0px;
    width: 90%;
    color: grey;
    font-size: 12px;
    padding-left: 5px;
}

.wt-contact-overlap-form input::placeholder {
    font-family: Georgia;
    padding-left: 5px;
    font-size: 12px;
}

.wt-input-pair {
    width: 100%;
    display: flex;
    // flex-direction: column;
    gap: 40px;
}

.wt-range-picker-clean .rs-input {
    background-color: rgb(255, 255, 255);
}

.wt-range-picker-clean .rs-input-group.rs-input-group-inside {
    border: none;
}

.wt-input-pair span {
    font-family: Georgia;
    color: #ABABAB;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
}

.wt-disclaimer {
    font-family: SharpGrotesk;
    font-size: 12px;
}

.wt-contact-tip-container {
    gap: 6px;
    display: flex;
    flex-direction: column;
    @include respond-to(medium) {
        margin-left: 24px;
    }
}

.wt-send-consult {
    padding: 10px 20px;
    border-radius: 5px 0px 5px 5px;
    background-color: #E69000;
    color: white;
    font-family: Georgia;
    font-weight: bold;
    font-size: 15px;
    @include respond-to(medium) {
        border-radius: 10px 0px 10px 10px;
        width: 97%;
        margin: auto;
        padding: 14px 20px;
    }
}

.wt-brands-container h3 {
    color: white;
    font-family: Georgia;
    font-weight: bold;
    padding: 57px 15.2% 34px;
    @include respond-to(medium) {
        margin-top: -63px;
        width: 73%;
        font-size: 8vw;
        padding: 57px 8% 34px;
    }
}

.wt-brands-icons {
    width: 71%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @include respond-to(medium) {
        // width: 84%;
        // flex-wrap: wrap;
    }
}

.wt-brands-icons img {
    // width: 22%;
    // height: 60px;
    max-height: 9vh;
    width: auto;
    margin: 0 10px;
}

.wt-flower {
    width: 23px;
    position: absolute;
    top: 20px;
    left: 14%;
    @include respond-to(medium) {
        left: 6%;
    }
}

.wt-test .alice-carousel__dots-item {
    background-color: #019E8C !important;
    width: 20px !important;
    border-radius: 4px;
    height: 5px;
    transition: 0.4s;
}

.wt-test .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #C4C5C1 !important;
    width: 40px !important;
}

.wt-test .alice-carousel__dots {
    margin-top: 0px !important;
}

.wt-contact-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 43px;
}

.wt-contact-mobile input {
    border: none;
    border-bottom: 2.4px solid grey;
    padding: 7px 0px;
    width: 100%;
    color: grey;
    font-size: 16px;
    padding-left: 5px;
    background-color: transparent;   
    font-family: Georgia;
    font-weight: bold;
}

.wt-contact-mobile input::placeholder {
    color: rgb(164, 164, 164);
    // font-size: 10px;
}

.wt-range-mobile {
    width: 50% !important;
}

.wt-mobile-dp {
    display: flex;
    gap: 30px;
}

.wt-textarea-mobile {
    border: none;
    border-bottom: 2.4px solid grey;
}

.wt-contact-mobile span {
    font-size: 10px;
    line-height: 1;
    font-family: SharpGrotesk;
    font-weight: bold;
    color: #B0B0B0;
}

.mt-brands-mobile {
    display: flex;
    width: 84%;
    margin: auto;
    justify-content: space-between;
}

.wt-range-mobile {
    border-bottom: 2.4px solid grey !important;
}

.wt-test .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 10px;
}

//Checkout specific contact styles
div.whiteDateRange  div.rs-input-group-inside{
    background-color: white!important;
}
div.whiteDateRange input.rs-input{
    background-color: white!important;
}
div.whiteDateRange .rs-picker-daterange.rs-picker-subtle .rs-input-group{
    background: white!important;
    border-bottom: 1px solid lightgray!important;
}
div.whiteDateRange .rs-picker-toggle-wrapper{
    border:none!important;
}