@import '../../../src/Views/styles/breakpoints.scss';
@import '../../../src/Views/styles/mixins.scss';
@import '../../../src/Views/styles/variables.scss';


.background-tit{
    background-image: url('../../assets/2. HISTORIA/13.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 7%;
    padding-bottom: 7%;
}
.title-history{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: white;
    text-align: left;
}
.title-firstStep{
    font-size: 2.4rem!important;
    letter-spacing: var(--letter-spacing);
    line-height: 2.5rem;
    color: #374a49!important;
    font-family: NovaBlack;
}
.texto-firstStep{
    letter-spacing: var(--letter-spacing);
    line-height: 1.3em;
    font-size: 1rem;
    font-weight: 400;
    color:#374a49!important;
    font-family: Nova;
}
.pics-history{
    border-radius: 5rem;
}
.background-firstSteps{
    background-image: url('../../assets/7. BACKS/Recurso\ 6-8.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.background-teamWork{
    background-image: url('../../assets/7. BACKS/Recurso\ 7-8.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.back-proposal{
    background-image: url('../../assets/7. BACKS/Recurso 8-8.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.title-proposal{
    font-size: 2.4rem;
    color: #fff;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    margin-top: 20%;
    margin-bottom: 16%;
}
.margin-top-proposal{
    margin-top: 60px;
}
.texto-proposal{
    line-height: 1.2rem!important;
    letter-spacing: var(--letter-spacing);
    font-size: .8rem;
    color:#fff!important;
    font-family: Nova;
}
.video-section{
    background-color: transparent;
    height: 90vh;
    background-size: cover;
    background-position: center;
}
.title-team{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    color: #20b19d;
    text-align: center;
}
.subtitle-team{
    line-height: 1.2em!important;
    letter-spacing: var(--letter-spacing);
    font-size: .9rem;
    font-weight: 400;
    color:#374a49!important;
    font-family: Nova;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}
.pics-team{
    border-radius: 4.5rem;
    text-align: center;
}
.button-linkedin1{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border-color: transparent;
    font-size: 1rem;
    font-weight: 500!important;
    padding: 10px 50px 10px 50px;
    position: absolute;
    bottom: 16.5%;
    left: 36.5%;
}
.button-linkedin2{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    letter-spacing: var(--letter-spacing);
    font-family: Nova;
    border-radius: 2em;
    color: white;
    border-color: transparent;
    font-size: 1rem;
    font-weight: 500!important;
    padding: 10px 50px 10px 50px;
    position: absolute;
    bottom: 16.5%;
    left: 36.5%;
}
.name-team{
    font-size: 2.8rem;
    color: #374a49;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1.2em!important;
    text-align: center;
}
.margin-browser{
    margin-left: 100px;
}
.ceo-cco{
    font-size: 2.4rem;
    font-family: NovaBlack;
    letter-spacing: var(--letter-spacing);
    line-height: 1rem!important;
    color: #20b19d;
}
.margin-img-history{
    margin-top: 160px;
}

@media (max-width: 768px) {
    .background-tit{
        background-image: url('../../assets/2. HISTORIA/13.jpg');
        background-size: cover;
        background-position: center;
        padding-top: 15%;
        padding-bottom: 15%;
    }
    .title-history{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 1.2em!important;
        color: white;
        text-align: left;
    }
    .title-firstStep{
        font-size: 2.2rem;
        color: #374a49;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2.3rem!important;
    }
    .texto-firstStep{
        letter-spacing: var(--letter-spacing);
        line-height: 1.5rem!important;
        line-height: 1.4rem;
        font-size: 1rem;
        font-weight: 500;
        color:#374a49!important;
        font-family: Nova;
    }
    .title-proposal{
        font-size: 2.2rem;
        color: #fff;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 1.2em!important;
    }
    .texto-proposal{
        line-height: 1.3rem!important;
        letter-spacing: var(--letter-spacing);
        font-size: 1rem;
        color:#fff!important;
        font-family: Nova;
        text-align: left;
    }
    .video-section{
        background-color: transparent;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
    .align-center-mobile{
        text-align: center;
    }
    .button-linkedin-mobile{
        background-image: linear-gradient(to right, #57b4a4 , #00a193);
        font-family: Nova;
        border-radius: 2em;
        color: white;
        border-color: transparent;
        font-size: .9rem;
        font-weight: 500!important;
        padding: 10px 50px 10px 50px;
        position: absolute;
        bottom: 26%;
        left: 30%;
    }
    .margin-mobile{
        margin-left: 10px;
    }
    .ceo-cco{
        font-size: 2rem;
        font-family: NovaBlack;
        letter-spacing: .0125em!important;
        line-height: 1.2em!important;
        color: #20b19d;
    }
    .pics-team{
        border-radius: 2rem;
        text-align: center;
    }
    .title-team{
        font-size: 2.2rem;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        color: #20b19d;
        text-align: left;
    }
    .subtitle-team{
        line-height: 1.3rem!important;
        letter-spacing: var(--letter-spacing);
        font-size: 1rem;
        font-weight: 500;
        color:#374a49!important;
        font-family: Nova;
        text-align: left;
        padding-left:0;
        padding-right:0;
    }
    .name-team{
        font-size: 2rem;
        color: #374a49;
        font-family: NovaBlack;
        letter-spacing: var(--letter-spacing);
        line-height: 2rem!important;
        text-align: center;
    }
}

.history-header-container {
    display: flex;
    padding-top: 80px;
    background-color: #CEE1D0;
    position: relative;
    height: 500px;
    overflow: hidden;
    @include respond-to(medium) {
        height: unset;
        flex-direction: column;
    }
}

.us-header-text {
    width: 50%;
    height: 372px;
    position: relative;
    @include respond-to(medium) {
        width: 100%;
        height: unset;
    }
}

.us-header-pic {
    height: 406px;
    @include respond-to(medium) {
        height: 348px;
    }
}

.us-header-pic img {
    width: 50%;
    position: absolute;
    right: 0;
    /* top: 110px; */
    /* height: fit-content; */
    height: 500px;
    object-fit: cover;
    @include respond-to(medium) {
        width: 100%;
        position: unset;
        right: unset;
        height: 350px;
    }
}

.us-header-text h4 {
    font-family: Georgia;
    font-size: 30px;
    font-weight: bold;
    color: $notBlack;
    padding-bottom: 20px;
}

.us-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 428px;
    color: #001109;
    font-family: SharpGrotesk;
    font-size: 13px;
    right: 114px;
    position: absolute;
    top: 27%;
    @include respond-to(medium) {
        // width: 334px;
        // top: 18%;
        // left: 11%;
        // flex-direction: column;
        display: block;
        width: unset;
        position: unset;
        padding: 30px;
    }
}

.history-values-row {
    width: 84%;
    max-width: 1091px;
    margin: auto;
}

.team-container h4, .team-slider h4 {
    font-size: 27px;
    padding: 10px 0px 0px;
    font-family: Georgia;
    color: $notBlack;
    font-weight: bold;
}

.team-container {
    width: 84%;
    margin: auto;
    padding: 40px 0px;
    position: relative;
    max-width: 1114px;
    height: 1250px;
}

.team-slider {
    display: none;
    @include respond-to(medium) {
        display: block;
        padding: 20px 30px;
        height: 68vh;
        position: relative;
    }
}

.team-member span {
    color: #0F4749;
    font-family: SharpMedium20;
    padding: 17px 0px 0px;
}

.team-member p {
    color: $notBlack;
    font-family: SharpGrotesk;
    font-size: 15px;
}

.team-logo {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 45%;
}

.team-members-container {
    display: flex;
    padding: 40px 0px;
    // justify-content: space-between;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    @include respond-to(medium) {
        width: unset;
    }
}

.team-member img {
    width: 76%;
}

.history-values-container {
    background-color: #D9D9D9;
    height: 590px;
    width: 100%;
    @include respond-to(medium) {
        height: 880px;
    }
}

.history-icon-container {
    width: 120px;
    @include respond-to(medium) {
        display: flex;
        margin: auto;
        justify-content: center;
    }
}

.history-icon-container img {
    @include respond-to(medium) {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 168px;
    }
}


.history-icons-container {
    display: flex;
    max-width: 1538px;
    justify-content: space-between;
    margin: auto;
    @include respond-to(medium) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
}

.history-values-container h5 {
    font-family: Georgia;
    font-weight: bold;
    color: #0F4749;
    font-size: 26px;
    @include respond-to(medium) {
        margin-top: 20px;
    }
}

.history-phrase-dots {
    font-size: 8px;
    color: #008880;
    @include respond-to(medium) {
        font-size: 13px;
        margin-bottom: 20px;
    }
}

.history-texts-container {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    max-width: 1230px;
    @include respond-to(medium) {
        width: unset;
        flex-direction: column;
        align-items: center;
    }
}

.history-text {
    width: 45%;
    padding: 20px;
    font-family: SharpMedium20;
    font-size: 16px;
    line-height: 2;
    // font-weight: bold;
    @include respond-to(medium) {
        padding: unset;
        line-height: unset;
        font-weight: unset;
        width: 83%;
        font-size: 15px;
    }
}

.history-text-1 {
    width: 48%;
    padding: 20px;
    font-family: SharpGrotesk;
    font-size: 14px;
    line-height: 2;
    color: $notBlack;
    @include respond-to(medium) {
        width: 94%;
        font-size: 13px;
        line-height: unset;
        color: #606060;
    }
}

.team-item-carousel {
    // width: 323px !important;
    // margin-left: 4px;
}

.team-carousel {
    overflow: unset;
    margin-top: 60px;
    margin-left: 32px;
}

.team-dotlist {
    position: absolute;
    bottom: -100px;
}

.team-dotlist .react-multi-carousel-dot {}


.team-dotlist .react-multi-carousel-dot button {
    background-color: #009F8A;
    border: none;
    width: 20px;
    height: 10px;
    border-radius: 10px;
    transition: 0.6s;
}

.team-dotlist .react-multi-carousel-dot--active button {
    background-color: #C4C4C1;
    border: none;
    width: 40px;
    height: 10px;
    border-radius: 10px;
}

.team-logo-slider {
    position: absolute;
    bottom: 0px;
    width: 82%;
    @include respond-to(medium) {
        z-index: -1;
        left: 18px;
        width: 88%;
    }
}
