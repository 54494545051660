@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';


.home-banner-fallback-image{
  width: 100%;
  @include respond-to('medium') {
    height: 100%;
    width: auto;
  }
}
.static-banner {
    // background-image: url('../../assets/banner1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 75vh;
    position: relative;
    overflow: hidden;
    @include respond-to('medium') {
      // background-image: url('../../assets/1. INICIO/static-banner-mobile.jpg');
      height: 460px;
      position: relative;
    }
}

.video-background {
  width: 100%; /* Cover the full container width */
  height: 100%; /* Cover the full container height */
  object-fit: cover; /* Ensure aspect ratio is maintained while covering area */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Position the video behind the content */
}


.content-overlay {
  position: relative; /* Positioned over the video */
  z-index: 1; /* Higher index than the video */
  /* Styling for the content to ensure it's visible and contrasts with the video */
}

.phrase-home {
  top: 40%;
  display: flex;
  // width: 80%;
  // left: 14%;
  margin-left: 13%;
  justify-content: center;
  font-size: 4.4vw;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  letter-spacing: 0.0125em !important;
  line-height: 1.2em !important;
  color: white;
  position: absolute;
    @include respond-to('medium') {
    width:100%;
    margin-left: unset;
    font-size: 28px;
    text-align: center;
    position: absolute;
    left: 0;
    }
  }

  .logo-banner {
    width: 242px;
    margin-bottom: 40px;
    opacity: 0.1;
    position: absolute;
    top: 28%;
    left: 42%;
    @include respond-to('medium') {
      display: none;
      width: 0px;
    }
  }