@import '../../Views/styles/breakpoints.scss';
@import '../../Views/styles/mixins.scss';
@import '../../Views/styles/variables.scss';

.body {
    background-color: transparent;
    color:#374a49;
    font-weight: 800;
    font-size: 1rem;
    font-family: Nova;
    margin: 0 auto;
}
.button{
    background-image: linear-gradient(to right, #57b4a4 , #20b19d);
    border-radius: 2rem;
    color: #fff;
    border: transparent;
    font-size: .7rem;
}
#navbar > a.active,a:hover{
    /* background-color: #9e9e9e3b; */
    /* border-radius: 2em; */
    cursor: pointer;
    /* padding: 4px 10px!important; */
}
#navbar{
    @include respond-to(medium) {
        margin-top: 12px!important;
    }
    
}
#navbar > a.logo:hover{
    background-color: initial;
    border-radius: initial;
    cursor: pointer;
    /* padding:0px 0px 0px 10px!important; */
}
.nav a {
    font-family: Nova;
    display: inline-block;
    padding: 0px 0px 0px 10px;
    color:#feffff!important;
    text-transform: none;
    font-size: .8rem;
    font-weight: 800!important;
    @include respond-to(medium) {
        color: grey !important;
    }
}

@media (max-width: 768px) {
    .logo{
        text-align: center;
        border: transparent!important;
    }
    .align-items-navbar{
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        gap: 7px;
    }
    /* a.nav-link.button {
        color: white!important;
        padding: 3px 5px;
    } */
    // .navBarLanguage{
    //     border-radius: 2rem;
    //     border: 1px solid black;
    //     padding: 10px 20px!important;
    //     font-weight: bold;
    //     font-size: 13px;
    //     width: 60%;
        
    // }
    a.nav-link.button {
        color: white!important;
        padding: 3px 5px;
        width: 60%;
        margin-top: 20px;
    }
    .nav a{
        border-radius: .7rem;
        width: 60%;
        cursor: pointer;
        font-family: Nova;
        font-size: .8rem;
        border-style: #374a49;
        border: 1px solid;
        padding: 10px 0px!important;
    }
}

.navbar-light .navbar-toggler{
    border:none;
}
a.nav-link.button {
    color: white!important;
    padding: 3px 5px;
}
.navBarLanguage{
    border-radius: 2rem;
    border: 1px solid #374a49;
    // padding: 0px 20px;
    font-weight: bold;
    font-size: 12px;
    font-family: Georgia;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
	-moz-appearance: none;
    
}
.input-navbar-lang{
    
    background: transparent;
    color: white;
    border: none;
}

.border-link li a:hover{
    color: #009F8A !important;
}

.border-link li a {

    // text-shadow: 1px 1px 0px black, 0 0 1em grey, 0 0 0.2em black;
    @include respond-to(medium) {
        text-shadow: none;
        color: black;
    }
    /* font-family: sans; color: white; */
}

.logo img {
    @include respond-to('medium') {
        display: none;
    }
}

#navbarSupportedContent {
    @include respond-to(medium) {
        background-color: rgba(255, 255, 255, 0.92);
        border-radius: 20px;
        padding: 20px;
    }
}

.navbar-brand {
    @include respond-to(medium) {
    float: left;
    // margin-left: 15px;
    background-image: url('../../assets/icons/whitelogo.png');
    height: 40px;
    width: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10rem !important;
    }
  }
  
  .navbar-toggler {
    float: right;
    margin-right: 15px;
    color: white;
    background-color: red($color: #000000);
  }

  .navbar {
    @include respond-to(medium) {
        display: flex;
        justify-content: space-around !important;
    }
  }

  .altNav {
    font-size: 12px !important;
    font-family: Georgia !important;
  }

  .nav-item .active {
    color: $welcome-green !important;
  }

  .hover-effect:hover {
    color:$welcome-green;
  }

  .nav-custom-mobile {
    @include respond-to(medium) {
        width: 15%;
    }
  }

  .navbar-custom-toggler-icon {
    @include respond-to(medium) {
        width: 2rem;
        padding-top: 10px;
    }
  }

  .navbar-nav{
    height: 45px;
    @include respond-to(medium) {
        height: 100%;
    }    
  }

  @media (min-width: 870px) {
    .navbar-expand-lg .navbar-nav {
        align-items: baseline;
    }
  }
  

  